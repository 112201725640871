import React, { memo } from "react";
import { Text, THEME } from "@dhl-official/react-ui-library";
import { FormattedDate } from "react-intl";
import PropTypes from "prop-types";
import styled from "styled-components";
import Copy from "../../../../../assets/copy/index.json";
import { RELEASE_STATUS } from "../../../../../utils/constants";
import { getColorForStatus } from "../../../../../utils";

const CURRENT_DATE = Date.now();

// #region Component Styles
const Container = styled.div``;

const Application = styled(Text).attrs({
  isParagraph: true,
  weight: 700,
  size: Text.TINY,
})`
  margin: 0;
  margin-top: ${THEME.getUnit("regular")};
`;

const Info = styled(Text).attrs({ isParagraph: true })`
  margin: 0;
`;

const Locales = styled.span`
  color: ${THEME.getGrayColor("700")};
`;

const Highlight = styled(Text).attrs({
  size: Text.BIG,
  isParagraph: true,
})`
  color: ${({ status }) => getColorForStatus(status)};
  margin: 0;
  margin-top: -4px;
`;

const ReleaseList = styled.ul``;

const Release = styled.li`
  margin-top: ${THEME.getUnit("small")};

  :first-of-type {
    margin-top: 0;
  }
`;
// #endregion

const getLanguages = (languages) =>
  languages.map((l) => Copy[`Language.${l}`]).join(", ");

const getContent = (status, releases) => {
  switch (status) {
    case RELEASE_STATUS.NOT_PLANNED:
      return <Highlight status={status}>Not Planned</Highlight>;

    case RELEASE_STATUS.PARTIALLY_RELEASED:
    case RELEASE_STATUS.PLANNED:
    case RELEASE_STATUS.RELEASED:
      return releases.length > 0 ? (
        <ReleaseList>
          {[...releases.sort((a, b) => a.date - b.date)].map(
            ({ date, languages }) => (
              <Release key={date}>
                <Info>
                  <Locales>({getLanguages(languages)})</Locales>{" "}
                  {date <= CURRENT_DATE ? "Released" : "Planned Release"} on
                </Info>{" "}
                <Highlight status={status}>
                  <FormattedDate
                    day="numeric"
                    month="long"
                    value={date}
                    weekday="long"
                    year="numeric"
                  />
                </Highlight>
              </Release>
            )
          )}
        </ReleaseList>
      ) : null;

    default:
      return null;
  }
};

const RolloutStatus = ({ status, releases, application }) => (
  <Container>
    <Application>{application}</Application>
    {getContent(status, releases)}
  </Container>
);

RolloutStatus.propTypes = {
  application: PropTypes.string,
  releases: PropTypes.arrayOf(PropTypes.object),
  status: PropTypes.oneOf(Object.values(RELEASE_STATUS)),
};

RolloutStatus.defaultProps = {
  releases: [],
};

export default memo(RolloutStatus);
