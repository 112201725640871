// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import { deburr, escapeRegExp } from "lodash-es";
import {
  GET_GAQ_COUNTRY_SETTINGS_FAILURE,
  GET_GAQ_COUNTRY_SETTINGS_SUCCESS,
  GET_GAQ_COUNTRY_SETTINGS,
  QUERY_GAQ_COUNTRY,
  SELECT_GAQ_COUNTRY,
  SET_GAQ_COUNTRY,
} from "../../../../actions";
import { ROUTES, GAQ_COUNTRIES } from "../../../../utils/constants";
import RD from "../../../../utils/functional/remote-data";
import AC from "../../../../utils/functional/auto-complete";
import * as reducers from "../../..";

const getGAQCountrySettings = (country) => ({
  payload: country,
  type: GET_GAQ_COUNTRY_SETTINGS,
});

const getGAQCountrySettingsSuccess = (country) => (countrySettings) => ({
  payload: { country, countrySettings },
  type: GET_GAQ_COUNTRY_SETTINGS_SUCCESS,
});

const getGAQCountrySettingsFailure = (country) => (error) => ({
  error: true,
  payload: { country, error },
  type: GET_GAQ_COUNTRY_SETTINGS_FAILURE,
});

const normalizeValueForSearch = (input) => deburr(input.trim().toLowerCase());

const queryGAQCountry = (payload) => ({
  payload,
  type: QUERY_GAQ_COUNTRY,
});

const setGAQCountry = (payload) => ({
  payload,
  type: SET_GAQ_COUNTRY,
});

export const selectGAQCountryEffect =
  (country) =>
  (dispatch, getState, { history, window }) => {
    const countryField = reducers.getGAQCountryField(getState());

    if (AC.getValue(countryField) === country) {
      return;
    }

    dispatch({ type: SELECT_GAQ_COUNTRY, payload: country });

    const url = `${ROUTES.GAQ_COUNTRY_CONFIGURATIONS}/${country}`;

    if (!window.location.pathname.includes(url)) {
      history.push(`${ROUTES.GAQ_COUNTRY_CONFIGURATIONS}/${country}`);
    }
  };

export const queryGAQCountryEffect = (payload) => (dispatch) => {
  dispatch(queryGAQCountry(payload));
  const normalizedInput = normalizeValueForSearch(payload);

  const countryNameRegex = new RegExp(
    `${escapeRegExp(`${normalizedInput}`)}.*`,
    "i"
  );

  const options = GAQ_COUNTRIES.filter(([, label]) =>
    normalizeValueForSearch(label).match(countryNameRegex)
  );

  const selectedCountry = options.find(
    ([, label]) => normalizeValueForSearch(label) === normalizedInput
  );

  const optionsValues = options.map(([id, value]) => ({
    id,
    value,
  }));

  const shouldSelectCountry = selectedCountry && options.length === 1;

  if (shouldSelectCountry) {
    dispatch(selectGAQCountryEffect(selectedCountry[0]));

    return;
  }

  dispatch(setGAQCountry(AC.unselected(payload, optionsValues)));
};

export const getGAQCountrySettingsEffect =
  (country) =>
  (dispatch, getState, { api }) => {
    const countrySettings = reducers.getGAQCountrySettings(getState(), country);

    if (
      countrySettings &&
      (RD.isSuccess(countrySettings) || RD.isLoading(countrySettings))
    ) {
      return;
    }

    dispatch(getGAQCountrySettings(country));

    api
      .getGAQCountrySettings(country)
      .then(compose(dispatch, getGAQCountrySettingsSuccess(country)))
      .catch(compose(dispatch, getGAQCountrySettingsFailure(country)));
  };
