import React, { useState, useEffect, useCallback } from "react";
import PropTypes from "prop-types";
import styled, { css, keyframes } from "styled-components";
import { Link } from "react-router-dom";
import { IconChevron, THEME } from "@dhl-official/react-ui-library";
import { BASE_FONT_SIZE } from "../../../styles";

const ESC = 27;

const FONT_SIZE = `${14 / BASE_FONT_SIZE}rem`;

const LINK_TYPE = PropTypes.shape({
  label: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
});

// #region Component Styles
const SWING_IN = keyframes`
  from {
    opacity: 0;
    transform: scale(0.8) translateY(-10px) rotateX(-20deg);
    transform-origin: 50% 0;
  }

  to {
    opacity: 1;
    transform: scale(1) translateY(0px) rotateX(0deg);  }
`;

const Container = styled.div`
  cursor: default;
  display: inline-block;
  position: relative;
`;

const Title = styled(Link)`
  color: ${THEME.getFontColor("default")};
  display: inline-block;
  font-size: ${FONT_SIZE};
  font-weight: 700;
  margin-top: -${THEME.getUnit("regular")};
  padding: ${THEME.getUnit("regular")};
  text-decoration: none;
  transition: color ${THEME.getAnimationSpeed("regular")};

  &:hover,
  &:focus {
    color: ${THEME.getPrimaryColor("400")};
  }

  &:focus {
    ${({ $isOpen }) =>
      $isOpen
        ? css`
            outline: none;
            text-decoration: underline;
          `
        : css`
            outline: solid 1px ${THEME.getPrimaryColor("400")};
            outline-offset: -${THEME.getUnit("regular")};
          `}
  }
`;

const Arrow = styled(IconChevron)`
  display: inline-block;
  height: 1em;
  margin-left: 2px;
  transform: ${({ $isUp }) =>
    $isUp ? "rotateZ(-0.25turn) rotateY(0.5turn)" : "rotateZ(-0.25turn)"};
  transition: transform ${THEME.getAnimationSpeed("regular")};
  vertical-align: middle;
  width: 1em;
`;

const BubbleArrow = styled.div`
  position: relative;
  background: #ffff;
  z-index: 1;

  &:after,
  &:before {
    bottom: 100%;
    left: 50%;
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }

  &:after {
    border-color: rgba(0, 0, 0, 0);
    border-bottom-color: #ffff;
    border-width: 10px;
    margin-left: -10px;
  }

  &:before {
    border-color: rgba(204, 204, 204, 0);
    border-bottom-color: ${THEME.getGrayColor("300")};
    border-width: 11px;
    margin-left: -11px;
  }
`;

const Options = styled.ul`
  animation: ${THEME.getAnimationSpeed("fast")} ease-in-out 0s forwards
    ${SWING_IN};
  background-color: ${THEME.getFontColor("inverted")};
  box-shadow: 0 0 4px rgba(0, 0, 0, 0.25);
  display: block;
  margin-top: 50px;
  min-width: 170px;
  position: absolute;
  top: 0;
  z-index: 2;
  width: max-content;
`;

const App = styled.li`
  border-bottom: solid 1px ${THEME.getGrayColor("200")};

  &:last-of-type {
    border-bottom-color: transparent;
  }
`;

const NavLink = styled(Link)`
  color: ${THEME.getFontColor("default")};
  display: block;
  font-family: ${THEME.getFontFamily()};
  font-size: ${FONT_SIZE};
  padding: ${THEME.getUnit("regular")};
  text-decoration: none;
  transition: color ${THEME.getAnimationSpeed("regular")};

  &:hover {
    color: ${THEME.getPrimaryColor("400")};
  }

  &:focus {
    outline: solid 1px ${THEME.getFontColor("default")};
    outline-offset: -1px;
  }
`;
// #endregion

const NavMenu = ({ title, options }) => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const closeMenu = () => {
    setIsMenuOpen(false);
  };

  const closeMenuOnDocumentEvent = useCallback((event) => {
    if (
      event.type === "click" ||
      (event.type === "keydown" && event.keyCode === ESC)
    ) {
      closeMenu();
    }
  }, []);

  useEffect(() => {
    if (isMenuOpen) {
      document.addEventListener("click", closeMenuOnDocumentEvent);
      document.addEventListener("keydown", closeMenuOnDocumentEvent);
    } else {
      document.removeEventListener("click", closeMenuOnDocumentEvent);
      document.removeEventListener("keydown", closeMenuOnDocumentEvent);
    }
  }, [isMenuOpen]);

  const toggleMenu = () => {
    setIsMenuOpen((isOpen) => !isOpen);
  };

  const onTitleClick = (e) => {
    if (options.length > 0) {
      e.preventDefault();
      toggleMenu();
    }
  };

  return (
    <Container>
      <Title onClick={onTitleClick} $isOpen={isMenuOpen} to={title.url}>
        {title.label} {options.length > 0 && <Arrow $isUp={isMenuOpen} />}
      </Title>

      {isMenuOpen && (
        <Options>
          <BubbleArrow />
          {options.map(({ label, url }) => (
            <App key={label}>
              <NavLink to={url} onClick={closeMenu}>
                {label}
              </NavLink>
            </App>
          ))}
        </Options>
      )}
    </Container>
  );
};

NavMenu.propTypes = {
  title: LINK_TYPE.isRequired,
  options: PropTypes.arrayOf(LINK_TYPE),
};

NavMenu.defaultProps = {
  options: [],
};

export default NavMenu;
