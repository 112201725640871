// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable import/prefer-default-export */

import { TOGGLE_GAQ_ANALYTICS_BU_FILTER } from "../../../../../actions";

export const toggleGAQAnalyticsBUFilter = (payload) => ({
  type: TOGGLE_GAQ_ANALYTICS_BU_FILTER,
  payload,
});
