// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";

import loggedUserReducer, * as loggedUser from "./logged-user";

export default (initialLoggedUser) =>
  combineReducers({
    loggedUser: loggedUserReducer(initialLoggedUser),
  });

export const getLoggedUser = (state) => loggedUser.get(state.loggedUser);
