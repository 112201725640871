import React, { Suspense } from "react";
import PropTypes from "prop-types";
import { Redirect, Route } from "react-router-dom";
import { ROUTES } from "../../utils/constants";

const AsyncRoute = ({
  component: Component,
  exact,
  loggedUserPermissions,
  path,
  render,
  requiredUserPermission,
}) => {
  if (
    requiredUserPermission &&
    !loggedUserPermissions.includes(requiredUserPermission)
  )
    return <Redirect to={ROUTES.SIGN_IN} />;

  return (
    <Route
      exact={exact}
      path={path}
      render={(renderProps) => (
        <Suspense fallback="">
          {render ? render(renderProps) : <Component />}
        </Suspense>
      )}
    />
  );
};

AsyncRoute.propTypes = {
  component: PropTypes.elementType,
  exact: PropTypes.bool,
  loggedUserPermissions: PropTypes.array,
  path: PropTypes.string,
  requiredUserPermission: PropTypes.string,
  render: PropTypes.func,
};

AsyncRoute.defaultProps = {
  component: () => null,
  exact: false,
};

export default AsyncRoute;
