import React, { useRef, useState, useEffect } from "react";
import { compose, path } from "ramda";
import styled from "styled-components";
import { Redirect, useHistory } from "react-router-dom";
import {
  Button,
  Headline,
  IconChevron,
  InputField,
  INPUTFIELD_LABEL_TYPES,
  INPUTFIELD_TYPES,
  Link,
  Text,
  THEME,
  VALIDATIONMESSAGE_VARIANTS,
  ValidationMessage,
} from "@dhl-official/react-ui-library";
import PropTypes from "prop-types";
import RD from "../../utils/functional/remote-data";
import { MAX_WIDTH } from "../../styles";
import Logo from "../../assets/images/dhl-logo.svg";
import { ROUTES } from "../../utils/constants";

// #region Component Styles
const Container = styled.div`
  display: flex;
  margin-bottom: calc(${THEME.getUnit("large")} * 2);
  margin: auto;
  max-width: ${MAX_WIDTH};
  padding: 0 ${THEME.getUnit("large")};
`;

const SignInForm = styled.form`
  align-self: center;
  flex-shrink: 0;
  max-width: 400px;
  padding: calc(${THEME.getUnit("large")} * 2);
  text-align: right;
  width: 100%;

  :before,
  :after {
    background-color: ${THEME.getGrayColor("200")};
    border-radius: ${THEME.getRadius()};
    content: " ";
    display: block;
    height: 4px;
    margin: 0 auto calc(${THEME.getUnit("large")} * 2) auto;
    width: 50px;
  }

  :after {
    margin: calc(${THEME.getUnit("large")} * 2) auto 0 auto;
  }
`;

const Title = styled(Headline).attrs({
  designLevel: 2,
  tag: Headline.H2,
  noMargin: true,
})`
  text-align: left;
`;

const Intructions = styled(Text).attrs({ isParagraph: true })`
  color: ${THEME.getGrayColor("600")};
  margin: ${THEME.getUnit("large")} 0 ${THEME.getUnit("regular")} 0;
  text-align: left;
`;

const FormField = styled(InputField).attrs({
  isBlock: true,
  type: INPUTFIELD_TYPES.TEXT,
})`
  margin-bottom: ${THEME.getUnit("regular")};
  text-align: left;
`;

const PasswordField = styled(FormField).attrs({
  type: INPUTFIELD_TYPES.PASSWORD,
})`
  text-align: left;
`;

const SignInButton = styled(Button).attrs({
  type: Button.SUBMIT,
  isBlock: true,
})`
  margin-top: ${THEME.getUnit("regular")};
`;

const InvalidCredentials = styled(ValidationMessage).attrs({
  variant: VALIDATIONMESSAGE_VARIANTS.INVALID,
})`
  color: ${THEME.getPrimaryColor("400")};
  justify-content: center;
  margin-top: ${THEME.getUnit("small")};
  text-align: center;
`;

const BackHomepage = styled(Link)`
  align-items: center;
  display: inline-flex;
  margin-top: ${THEME.getUnit("regular")};
  text-decoration: none;
`;

const BackIcon = styled(IconChevron)`
  margin-right: 3px;
  height: 14px;
`;

const Hero = styled.div`
  background-color: ${THEME.getSecondaryColor("400")};
  box-sizing: border-box;
  flex: 1 0 0;
  height: 100vh;
  padding: calc(${THEME.getUnit("large")} * 2);
  text-align: right;
`;

const DHLLogo = styled(Logo)`
  height: 40px;
`;
// #endregion

const getEmailPlaceholder = () =>
  Math.random() < 0.5 ? "john.doe@dhl.com" : "mary.jane@dhl.com";

const SignIn = ({ loggedUser, signIn }) => {
  const history = useHistory();

  const usernameRef = useRef();
  const placeholderRef = useRef(getEmailPlaceholder());

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [hasTriedToSignIn, setHasTriedToSignIn] = useState(false);

  useEffect(() => usernameRef.current.focus(), []);

  if (RD.isSuccess(loggedUser)) {
    return <Redirect to={ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW} />;
  }

  const submitSignIn = (e) => {
    e.preventDefault();
    setHasTriedToSignIn(true);
    signIn(username, password);
  };

  return (
    <Container>
      <SignInForm onSubmit={submitSignIn}>
        <Title>Sign in to DCI Hub</Title>
        <Intructions>Please enter your credentials below</Intructions>

        <FormField
          id="login-email"
          onChange={compose(setUsername, path(["target", "value"]))}
          ref={usernameRef}
          value={username}
          variant={{
            label: "Email",
            placeholder: placeholderRef.current,
            type: INPUTFIELD_LABEL_TYPES.STATIC,
          }}
        />

        <PasswordField
          id="login-password"
          onChange={compose(setPassword, path(["target", "value"]))}
          value={password}
          variant={{
            label: "Password",
            placeholder: "Add your password",
            type: INPUTFIELD_LABEL_TYPES.STATIC,
          }}
        />
        <SignInButton>Sign in</SignInButton>

        {hasTriedToSignIn && RD.isError(loggedUser) && (
          <InvalidCredentials message="Invalid username and password" />
        )}

        <BackHomepage
          href={
            (history.location.state && history.location.state.redirectUrl) ||
            "/"
          }
          onClick={(e) => {
            e.preventDefault();
            const anchor = e.target;
            const href = anchor.getAttribute("href");
            history.push(href);
          }}
        >
          <BackIcon /> Back to DCI Hub
        </BackHomepage>
      </SignInForm>

      <Hero>
        <DHLLogo />
      </Hero>
    </Container>
  );
};

SignIn.propTypes = {
  loggedUser: PropTypes.object.isRequired,
  signIn: PropTypes.func.isRequired,
};

export default SignIn;
