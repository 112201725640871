import React from "react";
import { always } from "ramda";
import PropTypes from "prop-types";
import styled from "styled-components";
import { FormattedDate } from "react-intl";
import { Link, useLocation } from "react-router-dom";
import { Text, THEME } from "@dhl-official/react-ui-library";
import NavMenu from "./NavMenu";
import { ROUTES } from "../../utils/constants";
import RD from "../../utils/functional/remote-data";
import Logo from "../../assets/images/dhl-logo.svg";
import { BASE_FONT_SIZE } from "../../styles";

const FONT_SIZE = `${14 / BASE_FONT_SIZE}rem`;

const createLinkType = (label, url) => ({
  label,
  url,
});

// #region Component Styles
const Container = styled.header`
  background: linear-gradient(
    90deg,
    ${THEME.getSecondaryColor("400")},
    ${THEME.getSecondaryColor("400")} 48%,
    #ffe57f 70%,
    #fff0b2
  );
  padding: ${THEME.getUnit("regular")} ${THEME.getUnit("large")};
  padding-bottom: 0;
`;

const Content = styled.div`
  margin: auto;
  max-width: 1200px;
  display: flex;
`;

const LeftContainer = styled.div`
  flex: 1 0 0;
`;

const TitleContainer = styled.div`
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  padding-bottom: 0;

  @media screen and (min-width: ${THEME.getViewport("medium")}) {
    align-items: flex-end;
    flex-direction: row;
  }
`;

const LogoLink = styled(Link).attrs({ to: "/", "aria-label": "DHL Logo" })`
  &:focus {
    outline: solid 1px ${THEME.getPrimaryColor("400")};
  }
`;

const DCIHub = styled(Text).attrs({
  isParagraph: true,
})`
  color: ${THEME.getPrimaryColor("400")};
  font-size: 18px;
  margin: 0;
  font-weight: 700;
  transform: translateY(-4px);

  @media screen and (min-width: ${THEME.getViewport("medium")}) {
    margin-left: ${THEME.getUnit("regular")};
  }
`;

const DHLLogo = styled(Logo)`
  height: 38px;
`;

const NavContainer = styled.div`
  display: flex;
`;

const NavBar = styled.nav`
  margin-top: ${THEME.getUnit("regular")};
  flex-grow: 1;
`;

const NavList = styled.ul`
  margin: 0 -${THEME.getUnit("regular")};
`;

const NavItem = styled.li`
  display: inline-block;
  width: 100%;

  @media screen and (min-width: ${THEME.getViewport("medium")}) {
    width: auto;
  }
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const SignInLink = styled(Link).attrs(({ redirect }) => ({
  to: {
    pathname: ROUTES.SIGN_IN,
    state: { redirectUrl: redirect },
  },
}))`
  align-self: flex-end;
  justify-self: flex-end;
  color: ${THEME.getFontColor("default")};
  font-family: ${THEME.getFontFamily()};
  display: inline-block;
  font-size: ${FONT_SIZE};
  font-weight: 700;
  padding: ${THEME.getUnit("regular")};
  text-decoration: none;
  transition: color ${THEME.getAnimationSpeed("regular")};

  :hover,
  :focus {
    color: ${THEME.getPrimaryColor("400")};
  }

  :focus {
    outline: solid 1px ${THEME.getPrimaryColor("400")};
    outline-offset: -1px;
  }
`;

const SignOutButton = styled(SignInLink).attrs({ as: "button" })`
  background: transparent;
  border: 0;
  cursor: pointer;
`;

const LatestChangeDate = styled(Text).attrs({
  size: Text.SMALL,
})`
  flex: 1 0 0;
  align-self: flex-end;
  color: ${THEME.getGrayColor("700")};
  margin: 0 ${THEME.getUnit("regular")};
`;

const Highlight = styled.strong``;
// #endregion

const FS_OPTIONS = [
  createLinkType("Release Notes", ROUTES.FS_RELEASE_NOTES),
  createLinkType("FSX Release Notes", ROUTES.FSX_RELEASE_NOTES),
  createLinkType("Country Configurations", ROUTES.FS_COUNTRY_CONFIGURATIONS),
  createLinkType("Connectors Overview", ROUTES.CONNECTORS_OVERVIEW),
];

const ROLLOUT_OPTIONS = [
  createLinkType("Project Overview", ROUTES.PROJECT_OVERVIEW),
  createLinkType("Homepage Rollouts", ROUTES.HOMEPAGE_ROLLOUT_OVERVIEW),
  createLinkType("CJ Rollouts", ROUTES.CJ_ROLLOUT_OVERVIEW),
];

const GAQ_OPTIONS = [
  createLinkType("Release Notes", ROUTES.GAQ_RELEASE_NOTES),
  createLinkType("Country Configurations", ROUTES.GAQ_COUNTRY_CONFIGURATIONS),
  createLinkType(
    "Release Notes Administration",
    ROUTES.GAQ_RELEASE_NOTES_ADMIN
  ),
];

const SHIP_NOW_OPTIONS = [
  createLinkType("Release Notes", ROUTES.SHIP_NOW_RELEASE_NOTES),
];

const Header = ({ latestUpdateDate, loggedUser, signOut }) => {
  const location = useLocation();

  return (
    <Container>
      <Content>
        <LeftContainer>
          <TitleContainer>
            <LogoLink>
              <DHLLogo />
            </LogoLink>
            <DCIHub>DCI Hub</DCIHub>
          </TitleContainer>

          <NavContainer>
            <NavBar>
              <NavList>
                <NavItem>
                  <NavMenu
                    options={ROLLOUT_OPTIONS}
                    title={createLinkType(
                      "Rollout Progress",
                      ROUTES.CJ_ROLLOUT_OVERVIEW
                    )}
                  />
                </NavItem>
                <NavItem>
                  <NavMenu
                    options={FS_OPTIONS}
                    title={createLinkType("Frequent Shipment", ROUTES.FS)}
                  />
                </NavItem>
                <NavItem>
                  <NavMenu
                    options={GAQ_OPTIONS}
                    title={createLinkType("Get a Quote", ROUTES.GAQ)}
                  />
                </NavItem>
                <NavItem>
                  <NavMenu
                    options={SHIP_NOW_OPTIONS}
                    title={createLinkType("Ship Now", ROUTES.SHIP_NOW)}
                  />
                </NavItem>
                <NavItem>
                  <NavMenu
                    title={createLinkType(
                      "Browser Matrix",
                      ROUTES.BROWSER_MATRIX
                    )}
                  />
                </NavItem>
              </NavList>
            </NavBar>
          </NavContainer>
        </LeftContainer>

        <RightContainer>
          <LatestChangeDate>
            Latest Update{" "}
            <Highlight>
              <FormattedDate
                day="numeric"
                month="long"
                value={latestUpdateDate}
                year="numeric"
              />
            </Highlight>
          </LatestChangeDate>

          {RD.case(
            {
              success: always(
                <SignOutButton onClick={signOut}>Sign Out</SignOutButton>
              ),
              _: always(
                <SignInLink redirect={location.pathname}>Sign In</SignInLink>
              ),
            },
            loggedUser
          )}
        </RightContainer>
      </Content>
    </Container>
  );
};

Header.propTypes = {
  latestUpdateDate: PropTypes.instanceOf(Date),
  loggedUser: PropTypes.object.isRequired,
  signOut: PropTypes.func.isRequired,
};

export default Header;
