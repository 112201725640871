// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import { RELEASE_TYPES } from "../../../../utils/constants";

const INITIAL_FSX_STATE = [
  {
    countries: [
      { country: "IT", languages: ["en", "it"] },
      { country: "MY", languages: ["en"] },
      { country: "US", languages: ["en"] },
    ],
    date: new Date(2023, 4, 30),
    title: "Initial Pilot Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-30.05.2023",
  },

  {
    countries: [
      { country: "AU", languages: ["en"] },
      { country: "GB", languages: ["en"] },
      { country: "IN", languages: ["en"] },
    ],
    date: new Date(2023, 7, 1),
    title: "FSX Release Batch 1 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-01.08.2023",
  },

  {
    countries: [
      { country: "BO", languages: ["en", "es"] },
      { country: "BR", languages: ["en", "pt"] },
      { country: "CA", languages: ["en", "fr"] },
      { country: "CN", languages: ["zh"] },
      { country: "DE", languages: ["de", "en"] },
      { country: "MX", languages: ["en", "es"] },
      { country: "ES", languages: ["es", "en"] },
    ],
    date: new Date(2023, 7, 29),
    title: "FSX Release Batch 2 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-29.08.2023",
  },

  {
    countries: [
      { country: "AT", languages: ["en", "de"] },
      { country: "FR", languages: ["en", "fr"] },
      { country: "IE", languages: ["en"] },
      { country: "NG", languages: ["en"] },
      { country: "TR", languages: ["en", "tr"] },
    ],
    date: new Date(2023, 8, 5),
    title: "FSX Release Batch 3 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-05.09.2023",
  },

  {
    countries: [
      { country: "IL", languages: ["en"] },
      { country: "PL", languages: ["pl"] },
      { country: "JP", languages: ["ja"] },
      { country: "LK", languages: ["en"] },
    ],
    date: new Date(2023, 8, 26),
    title: "FSX Release Batch 4 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-26.09.2023",
  },

  {
    countries: [
      { country: "HR", languages: ["en", "hr"] },
      { country: "PT", languages: ["en", "pt"] },
      { country: "TH", languages: ["en", "th"] },
      { country: "KW", languages: ["en"] },
      { country: "BD", languages: ["en"] },
      { country: "GH", languages: ["en"] },
    ],
    date: new Date(2023, 9, 5),
    title: "FSX Release Batch 5 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-05.10.2023",
  },

  {
    countries: [
      { country: "AE", languages: ["en"] },
      { country: "CO", languages: ["en", "es"] },
      { country: "PH", languages: ["en"] },
      { country: "GR", languages: ["en", "el"] },
    ],
    date: new Date(2023, 9, 10),
    title: "FSX Release Batch 6 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-10.10.2023",
  },

  {
    countries: [
      { country: "EG", languages: ["en"] },
      { country: "KE", languages: ["en"] },
      { country: "UG", languages: ["en"] },
      { country: "QA", languages: ["en"] },
      { country: "SV", languages: ["en", "es"] },
      { country: "CH", languages: ["de", "fr", "it"] },
      { country: "FI", languages: ["en", "fi"] },
    ],
    date: new Date(2023, 9, 17),
    title: "FSX Release Batch 7 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-17.10.2023",
  },

  {
    countries: [
      { country: "EC", languages: ["en", "es"] },
      { country: "MA", languages: ["en", "fr"] },
      { country: "CY", languages: ["en", "el"] },
      { country: "KH", languages: ["en"] },
      { country: "HN", languages: ["en", "es"] },
    ],
    date: new Date(2023, 9, 24),
    title: "FSX Release Batch 8 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-24.10.2023",
  },

  {
    countries: [
      { country: "VE", languages: ["en", "es"] },
      { country: "OM", languages: ["en"] },
      { country: "ET", languages: ["en"] },
      { country: "BH", languages: ["en"] },
      { country: "GT", languages: ["en", "es"] },
    ],
    date: new Date(2023, 9, 31),
    title: "FSX Release Batch 9 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-31.10.2023",
  },

  {
    countries: [
      { country: "MU", languages: ["en"] },
      { country: "PA", languages: ["en", "es"] },
      { country: "AL", languages: ["en"] },
      { country: "AO", languages: ["en", "pt"] },
      { country: "IQ", languages: ["en"] },
    ],
    date: new Date(2023, 10, 7),
    title: "FSX Release Batch 10 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-07.11.2023",
  },

  {
    countries: [
      { country: "CI", languages: ["en", "fr"] },
      { country: "FJ", languages: ["en"] },
      { country: "PG", languages: ["en"] },
      { country: "ZW", languages: ["en"] },
      { country: "GA", languages: ["en", "fr"] },
    ],
    date: new Date(2023, 10, 14),
    title: "FSX Release Batch 11 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-14.11.2023",
  },

  {
    countries: [
      { country: "CM", languages: ["en", "fr"] },
      { country: "NI", languages: ["en", "es"] },
      { country: "SN", languages: ["en", "fr"] },
      { country: "MZ", languages: ["en", "pt"] },
      { country: "BA", languages: ["en", "bs"] },
      { country: "MG", languages: ["en", "fr"] },
    ],
    date: new Date(2023, 10, 21),
    title: "FSX Release Batch 12 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-21.11.2023",
  },

  {
    countries: [
      { country: "NO", languages: ["en", "no"] },
      { country: "ZA", languages: ["en"] },
      { country: "RO", languages: ["en", "ro"] },
      { country: "DK", languages: ["en", "da"] },
      { country: "KR", languages: ["en", "ko"] },
      { country: "HK", languages: ["en", "zh"] },
    ],
    date: new Date(2023, 10, 28),
    title: "FSX Release Batch 13 - Country Rollout",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-28.11.2023",
  },
  {
    countries: [
      { country: "BN", languages: ["en"] },
      { country: "DO", languages: ["en", "es"] },
      { country: "JM", languages: ["en"] },
      { country: "JO", languages: ["en"] },
      { country: "NP", languages: ["en"] },
      { country: "UY", languages: ["en", "es"] },
    ],
    date: new Date(2024, 0, 16),
    title: "New Countries Added (Batch 14)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-16.01.2024",
  },
  {
    countries: [
      { country: "BM", languages: ["en"] },
      { country: "BS", languages: ["en"] },
      { country: "BT", languages: ["en"] },
      { country: "KY", languages: ["en"] },
      { country: "LA", languages: ["en"] },
      { country: "MQ", languages: ["en", "fr"] },
      { country: "MV", languages: ["en"] },
      { country: "PY", languages: ["en", "es"] },
      { country: "TT", languages: ["en"] },
    ],
    date: new Date(2024, 1, 20),
    title: "New Countries Added (Batches 15 & 16)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-20.02.2024",
  },
  {
    countries: [
      { country: "BZ", languages: ["en", "es"] },
      { country: "RE", languages: ["en", "fr"] },
      { country: "TL", languages: ["en"] },
      { country: "VG", languages: ["en"] },
    ],
    date: new Date(2024, 1, 27),
    title: "New Countries Added (Batch 17)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-27.02.2024",
  },
  {
    countries: [
      { country: "CD", languages: ["en", "fr"] },
      { country: "GF", languages: ["en", "fr"] },
      { country: "LS", languages: ["en"] },
    ],
    date: new Date(2024, 2, 26),
    title: "New Countries Added (Batch 18)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-26.03.2024",
  },
  {
    countries: [
      { country: "BB", languages: ["en"] },
      { country: "GQ", languages: ["en", "es"] },
      { country: "IS", languages: ["en"] },
      { country: "MT", languages: ["en"] },
      { country: "YE", languages: ["en"] },
    ],
    date: new Date(2024, 3, 10),
    title: "New Countries Added (Batch 19)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-10.04.2024",
  },
  {
    countries: [
      { country: "BW", languages: ["en"] },
      { country: "HT", languages: ["en", "fr"] },
      { country: "MO", languages: ["en"] },
      { country: "MW", languages: ["en"] },
      { country: "SY", languages: ["en"] },
    ],
    date: new Date(2024, 3, 23),
    title: "New Countries Added (Batch 20)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-23.04.2024",
  },
  {
    countries: [
      { country: "BG", languages: ["en", "bg"] },
      { country: "CL", languages: ["en", "es"] },
      { country: "NZ", languages: ["en"] },
      { country: "SG", languages: ["en"] },
      { country: "TW", languages: ["en", "zh"] },
    ],
    date: new Date(2024, 4, 7),
    title: "New Countries Added (Batch 21)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-07.05.2024",
  },
  {
    countries: [
      { country: "DZ", languages: ["en", "fr"] },
      { country: "EE", languages: ["en", "et"] },
      { country: "KZ", languages: ["en", "ru"] },
      { country: "LT", languages: ["en", "lt"] },
    ],
    date: new Date(2024, 4, 21),
    title: "New Countries Added (Batch 22)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-21.05.2024",
  },
  {
    countries: [
      { country: "CR", languages: ["en", "es"] },
      { country: "GP", languages: ["en", "fr"] },
      { country: "LU", languages: ["en", "fr", "de"] },
    ],
    date: new Date(2024, 5, 4),
    title: "New Countries Added (Batch 23)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-04.06.2024",
  },
  {
    countries: [
      { country: "LB", languages: ["en", "ar"] },
      { country: "MM", languages: ["en"] },
      { country: "SI", languages: ["en", "sl"] },
      { country: "TZ", languages: ["en"] },
      { country: "UA", languages: ["en", "uk"] },
    ],
    date: new Date(2024, 5, 18),
    title: "New Countries Added (Batch 24)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-18.06.2024",
  },
  {
    countries: [
      { country: "AR", languages: ["en", "es"] },
      { country: "BE", languages: ["en", "fr", "nl"] },
      { country: "CZ", languages: ["en", "cs"] },
      { country: "HU", languages: ["en", "hu"] },
      { country: "ID", languages: ["en"] },
      { country: "LV", languages: ["en", "lv"] },
    ],
    date: new Date(2024, 6, 2),
    title: "New Countries Added (Batch 25)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-02.07.2024",
  },
  {
    countries: [
      { country: "MK", languages: ["en"] },
      { country: "PE", languages: ["en", "es"] },
      { country: "SA", languages: ["en", "ar"] },
      { country: "SE", languages: ["en", "sv"] },
      { country: "SK", languages: ["en", "sk"] },
      { country: "VN", languages: ["en", "vi"] },
    ],
    date: new Date(2024, 6, 16),
    title: "New Countries Added (Batch 26)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-16.07.2024",
  },
  {
    countries: [
      { country: "CG", languages: ["en", "fr"] },
      { country: "GM", languages: ["en"] },
      { country: "ML", languages: ["en", "fr"] },
      { country: "NA", languages: ["en"] },
      { country: "SL", languages: ["en"] },
    ],
    date: new Date(2024, 6, 30),
    title: "New Countries Added (Batch 27)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-30.07.2024",
  },
  {
    countries: [
      { country: "BJ", languages: ["en", "fr"] },
      { country: "PK", languages: ["en"] },
      { country: "RS", languages: ["en", "sr"] },
      { country: "SZ", languages: ["en"] },
      { country: "ZM", languages: ["en"] },
    ],
    date: new Date(2024, 7, 13),
    title: "New Countries Added (Batch 28)",
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "fsx-countries-13.08.2024",
  },
].sort((a, b) => b.date - a.date);

const reducer = (state = INITIAL_FSX_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
