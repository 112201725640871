// 9fbef606107a605d69c0edbcd8029e5d

import { always, complement, compose, equals } from "ramda";
import {
  CLOSE_MODAL,
  OPEN_CJ_MAP_REGION_DATA_MODAL,
  OPEN_EDIT_BUSINESS_UNIT_MODAL,
  OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL,
  OPEN_SHARE_MODAL,
  OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL,
  SET_BUSINESS_UNIT_OPTIONS,
  SET_BUSINESS_UNIT_THRESHOLD_ENABLED,
  SET_BUSINESS_UNIT_THRESHOLD,
  UPDATE_BUSINESS_UNIT,
  OPEN_PROJECT_OVERVIEW_DETAILS_MODAL,
} from "../../actions";
import { MODALS, PRODUCTS } from "../../utils/constants";
import FF, {
  Meta as M,
  Validation as V,
} from "../../utils/functional/form-field";

const INITIAL_STATE = null;

const createThresholdFields = (daily, weekly, monthly) => ({
  daily: FF.valid(
    M.create([V.required("Add daily threshold")], "daily"),
    daily
  ),
  monthly: FF.valid(
    M.create([V.required("Add monthly threshold")], "monthly"),
    monthly
  ),
  weekly: FF.valid(
    M.create([V.required("Add weekly threshold")], "weekly"),
    weekly
  ),
});

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_BUSINESS_UNIT:
    case CLOSE_MODAL:
      return null;

    case OPEN_SHARE_MODAL:
      return {
        type: MODALS.SHARE,
        data: payload,
      };

    case OPEN_DELETE_GAQ_RELEASE_NOTE_MODAL:
      return {
        type: MODALS.DELETE_RELEASE_NOTE,
        data: payload,
      };

    case OPEN_PROJECT_OVERVIEW_DETAILS_MODAL:
      return {
        type: MODALS.PROJECT_OVERVIEW_DETAILS,
        data: payload,
      };

    case OPEN_EDIT_BUSINESS_UNIT_MODAL: {
      const { urgency, threshold, identity } = payload;
      const hasThreshold = Boolean(threshold);

      return {
        type: MODALS.EDIT_BUSINESS_UNIT,
        data: {
          identity,
          threshold: {
            fields: hasThreshold
              ? createThresholdFields(
                  threshold.dailyShipments,
                  threshold.weeklyShipments,
                  threshold.monthlyShipments
                )
              : createThresholdFields("0", "0", "0"),
            isEnabled: hasThreshold,
          },
          options: identity.product === PRODUCTS.PARCEL ? urgency : undefined,
        },
      };
    }

    case SET_BUSINESS_UNIT_THRESHOLD: {
      const [key, value] = payload;
      const field = state.data.threshold.fields[key];

      return {
        ...state,
        data: {
          ...state.data,
          threshold: {
            ...state.data.threshold,
            fields: {
              ...state.data.threshold.fields,
              [key]: compose(FF.validate, FF.map(always(value)))(field),
            },
          },
        },
      };
    }

    case SET_BUSINESS_UNIT_THRESHOLD_ENABLED:
      return {
        ...state,
        data: {
          ...state.data,
          threshold: {
            ...state.data.threshold,
            isEnabled: payload,
          },
        },
      };

    case SET_BUSINESS_UNIT_OPTIONS: {
      const { options } = state.data;

      return {
        ...state,
        data: {
          ...state.data,
          options: options.includes(payload)
            ? options.filter(complement(equals(payload)))
            : [...options, payload],
        },
      };
    }

    case OPEN_CJ_MAP_REGION_DATA_MODAL:
      return {
        type: MODALS.CJ_MAP_REGION,
        data: payload,
      };

    case OPEN_HOMEPAGE_MAP_REGION_DATA_MODAL:
      return {
        type: MODALS.HOMEPAGE_MAP_REGION,
        data: payload,
      };

    default:
      return state;
  }
};

export default reducer;

export const getOpenedModal = (state) => state && state.type;

export const getModalData = (state) => (state && state.data) || null;

export const getIsEditBusinessUnitModalFormValid = (state) => {
  if (!state || state.type !== MODALS.EDIT_BUSINESS_UNIT) {
    return false;
  }

  const { threshold } = state.data;

  if (!threshold.isEnabled) {
    return true;
  }

  const fields = Object.values(threshold.fields);
  const validFields = fields.filter(FF.isValid);

  return validFields.length === fields.length;
};
