// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";

const INITIAL_STATE = [
  "AR",
  "AT",
  "AU",
  "BR",
  "CA",
  "CO",
  "EC",
  "ES",
  "FR",
  "GR",
  "IN",
  "IT",
  "JP",
  "KR",
  "MX",
  "MY",
  "NL",
  "NZ",
  "PH",
  "PL",
  "PT",
  "RO",
  "SG",
  "TH",
  "TW",
  "US",
  "ZA",
  "EE",
  "ID",
  "LV",
  "HR",
  "MA",
  "KH",
  "NO",
  "FI",
  "RS",
  "DE",
  "IL",
  "SA",
  "HK",
  "QA",
  "KE",
  "CL",
  "PE",
  "NG",
  "HU",
  "CH",
  "EG",
  "VN",
  "SV",
  "AE",
  "BD",
  "PK",
  "IE",
  "LK",
];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
