// 9fbef606107a605d69c0edbcd8029e5d

import { prop } from "ramda";
import RD from "../../../../utils/functional/remote-data";
import AC from "../../../../utils/functional/auto-complete";
import {
  GET_GAQ_COUNTRY_SETTINGS_FAILURE,
  GET_GAQ_COUNTRY_SETTINGS_SUCCESS,
  GET_GAQ_COUNTRY_SETTINGS,
  SELECT_GAQ_COUNTRY,
  SET_GAQ_COUNTRY,
} from "../../../../actions";

const INITIAL_STATE = {
  countryField: AC.unselected("", []),
  lastSelectedCountry: null,
  settings: {},
};

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_GAQ_COUNTRY:
      return {
        ...state,
        countryField: payload,
      };

    case SELECT_GAQ_COUNTRY:
      return {
        ...state,
        countryField: AC.selected(payload, []),
        lastSelectedCountry: payload,
      };

    case GET_GAQ_COUNTRY_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload]: RD.loading(),
        },
      };

    case GET_GAQ_COUNTRY_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload.country]: RD.success(payload.countrySettings),
        },
      };

    case GET_GAQ_COUNTRY_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload.country]: RD.error(payload.error),
        },
      };

    default:
      return state;
  }
};

export const getCountryField = prop("countryField");

export const getLastSelectedCountry = prop("lastSelectedCountry");

export const getCountrySettings = (state, country) => state.settings[country];

export default reducer;
