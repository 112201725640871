import React, { useRef, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { always } from "ramda";
import styled from "styled-components";
import {
  Button,
  IconMinusCircleFilled,
  IconPlus,
  InputField,
  INPUTFIELD_TYPES,
  INPUTFIELD_LABEL_TYPES,
  INPUTFIELD_VALIDATION_TYPES,
  Text,
  THEME,
} from "@dhl-official/react-ui-library";
import FF from "../../../../utils/functional/form-field";

// #region Component Styles
const AddThresholdButton = styled(Button).attrs({
  variant: Button.TEXT,
})`
  padding: 0;
`;

const IconAdd = styled(IconPlus)`
  width: 10px;
  height: 10px;
  margin-right: 3px;
`;

const Title = styled(Text).attrs({ isParagraph: true })`
  font-weight: 600;
  margin: 0;
  margin-bottom: ${THEME.getUnit("small")};
`;

const ThresholdEditContainer = styled.div`
  display: flex;
`;

const ThresholdInputContainer = styled.div``;

const ThresholdInput = styled(InputField).attrs({
  type: INPUTFIELD_TYPES.NUMBER,
})`
  display: block;
  margin-bottom: ${THEME.getUnit("small")};

  > input {
    appearance: textfield;
  }

  > input::-webkit-outer-spin-button,
  > input::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  :last-of-type {
    margin-bottom: 0;
  }
`;

const ThresholdParenthesis = styled.div`
  width: 20px;
  border-bottom-right-radius: ${THEME.getRadius()};
  border-top-right-radius: ${THEME.getRadius()};
  border: solid 1px ${THEME.getGrayColor("400")};
  border-left: 0;
  margin: 0 ${THEME.getUnit("small")};
`;

const ThresholdRemoveButton = styled(Button).attrs({
  variant: Button.SECONDARY,
  title: "Remove Thresholds",
})`
  align-items: center;
  align-self: center;
  display: flex;
  padding: ${THEME.getUnit("small")};
`;

const RemoveThresholdIcon = styled(IconMinusCircleFilled)`
  width: 14px;
  display: inline-block;
  margin-right: 3px;
`;
// #endregion

const getValidation = FF.case({
  invalid: (_, { feedbackMessageId }) => ({
    message: feedbackMessageId,
    type: INPUTFIELD_VALIDATION_TYPES.INVALID,
  }),
  _: always(undefined),
});

const Threshold = ({
  threshold,
  setBusinessUnitThreshold,
  setBusinessUnitThresholdEnabled,
}) => {
  const firstFieldRef = useRef();
  const { fields } = threshold;
  const isEnabled = Boolean(threshold) && threshold.isEnabled;

  useEffect(() => {
    if (isEnabled) {
      firstFieldRef.current.focus();
    }
  }, [isEnabled]);

  if (!threshold.isEnabled) {
    return (
      <AddThresholdButton onClick={() => setBusinessUnitThresholdEnabled(true)}>
        <IconAdd />
        Add Thresholds
      </AddThresholdButton>
    );
  }

  const getInputField = (field, label, ref) => (
    <ThresholdInput
      ref={ref}
      onChange={(e) => {
        setBusinessUnitThreshold(FF.getMeta(field).id, e.target.value);
      }}
      validation={getValidation(field)}
      value={FF.getValue(field)}
      variant={{
        type: INPUTFIELD_LABEL_TYPES.STATIC,
        label,
        placeholder: "Add minimum volume",
      }}
    />
  );

  return (
    <Fragment>
      <Title>Thresholds</Title>
      <ThresholdEditContainer>
        <ThresholdInputContainer>
          {getInputField(fields.daily, "Daily", firstFieldRef)}
          {getInputField(fields.weekly, "Weekly")}
          {getInputField(fields.monthly, "Monthly")}
        </ThresholdInputContainer>

        <ThresholdParenthesis />

        <ThresholdRemoveButton
          onClick={() => setBusinessUnitThresholdEnabled(false)}
        >
          <RemoveThresholdIcon /> Remove
        </ThresholdRemoveButton>
      </ThresholdEditContainer>
    </Fragment>
  );
};

Threshold.propTypes = {
  setBusinessUnitThreshold: PropTypes.func.isRequired,
  setBusinessUnitThresholdEnabled: PropTypes.func.isRequired,
  threshold: PropTypes.shape({
    fields: PropTypes.object,
    isEnabled: PropTypes.bool.isRequired,
  }),
};

export default Threshold;
