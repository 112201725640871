// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import { RELEASE_TYPES } from "../../../../utils/constants";

const INITIAL_STATE = [
  {
    countries: [{ country: "AU", languages: ["en"] }],
    date: new Date(2021, 2, 17),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "sn-countries-17.03.2021",
  },

  {
    date: new Date(2021, 2, 17),
    features: [],
    isDetailed: false,
    summary: "Ship Now enabled in Australia",
    title: "🥇 First version of Ship Now",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.0.5",
  },

  {
    date: new Date(2021, 8, 23, 9, 0, 0),
    features: [
      "Updated styles and components (DUIL)",
      "Localization support",
      "Updated links to Login Portals and BU cards",
    ],
    isDetailed: false,
    summary:
      "After months of development, the new version of Ship Now was released, containing the ground-work necessary to enable Ship Now to different languages and updated styles (thanks to DUIL 👏)",
    title: "🆕 Improvements and updates",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.0_1",
  },

  {
    date: new Date(2021, 8, 23, 9, 1, 0),
    features: [
      "Test A: Ship Now and Get a Quote as separate journeys",
      "Test B: Quote & Ship Now as single get a quote journey",
    ],
    isDetailed: false,
    summary: "Launch date for A/B Test experiment for Australia.",
    title: "🅰️🅱️ Start of A/B Tests for AU",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.0_2",
  },

  {
    date: new Date(2021, 8, 30),
    features: [
      "New Icons",
      "New BU Cards",
      "Multi-language support",
      "Support for separate funnels in different journeys (Private and Business for Parcel & Documents or Cargo & Freight)",
    ],
    isDetailed: false,
    summary:
      "In this release the localization for Italy, the Netherlands, Mexico, and South Africa was complete. New BU cards are presented according to the availability of each country and funnel.",
    title: "New countries and localization complete",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.1",
  },

  {
    countries: [
      { country: "IT", languages: ["en", "it"] },
      { country: "NL", languages: ["en", "nl"] },
      { country: "MX", languages: ["en", "es"] },
      { country: "ZA", languages: ["en"] },
    ],
    date: new Date(2021, 9, 5),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "sn-countries-30.9.2021",
  },

  {
    date: new Date(2021, 9, 5),
    features: [
      "Test A: Ship Now and Get a Quote as separate journeys",
      "Test B: Quote & Ship Now as single get a quote journey",
    ],
    isDetailed: false,
    summary:
      "What is being tested: Simplification of entry points makes any impact on lead quality and e2e conversion rate.{br}{br}Expected benefit: Reducing fragmentation of tools, might reduce lead drop offs from DHL.com to BU, increasing conversion.",
    title:
      "🅰️🅱️ Ship Now is running A/B tests in 5 pilot countries (NL, IT, AU, MX, ZA)",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.0_0",
  },

  {
    date: new Date(2021, 9, 12),
    features: [],
    isDetailed: false,
    summary: "Changes in the BU Cards for the Netherlands",
    title: "Content update",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.2",
  },

  {
    date: new Date(2021, 10, 8),
    features: [],
    isDetailed: false,
    summary: "Small update to improve tracking for DGF CTAs",
    title: "Updated tracking parameters",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.4",
  },

  {
    date: new Date(2021, 10, 16),
    features: [],
    isDetailed: false,
    summary: "Small update to improve tracking",
    title: "Updated tracking parameters",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.5",
  },

  {
    date: new Date(2022, 0, 25),
    features: [],
    isDetailed: false,
    summary: "Small update to improve tracking for DHL Parcel in NL",
    title: "Updated tracking parameters",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.2.6",
  },

  {
    date: new Date(2022, 0, 25),
    features: [],
    isDetailed: false,
    summary: "Changed Australia configuration to include eCS",
    title: "New AU config",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.4.0",
  },

  {
    countries: [
      { country: "FR", languages: ["en", "fr"] },
      { country: "KR", languages: ["en", "ko"] },
    ],
    date: new Date(2022, 2, 22),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "sn-countries-22.3.2022",
  },

  {
    countries: [
      { country: "CN", languages: ["en", "zh"] },
      { country: "US", languages: ["en"] },
      { country: "GB", languages: ["en"] },
    ],
    date: new Date(2022, 2, 31),
    type: RELEASE_TYPES.COUNTRY_ROLLOUT,
    version: "sn-countries-31.3.2022",
  },

  {
    date: new Date(2022, 2, 31),
    features: [],
    isDetailed: false,
    summary:
      "Removed Phone CTAs from Express BU Cards and added a new modal window with an informational message",
    title: "Modal dialog for Express CTAs in AU and US",
    type: RELEASE_TYPES.FEATURE_RELEASE,
    version: "v1.4.2",
  },
].sort((a, b) => b.date - a.date);

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
