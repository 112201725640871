import PropTypes from "prop-types";
import { THEME } from "@dhl-official/react-ui-library";
import styled from "styled-components";
import getFlagForCountry from "./flag-for-country";
import flagsImage from "../../assets/images/flags.webp";

// #region Component Styles
const Flags = styled.i`
  background-image: url(${flagsImage});
  background-position: -462px -630px;
  background-repeat: no-repeat;
  background-size: 662px;
  display: inline-block;
  height: 35px;
  margin-right: ${THEME.getUnit("small")};
  vertical-align: middle;
  width: 35px;

  ${({ country }) => getFlagForCountry(country)};
`;
// #endregion

Flags.propTypes = {
  className: PropTypes.string,
  country: PropTypes.string.isRequired,
};

export default Flags;
