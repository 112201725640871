// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { connect } from "react-redux";
import * as reducers from "../../../reducers";
import * as actions from "../../../reducers/actions";
import EditBusinessUnitModal from ".";

const mapStateToProps = (state) => ({
  isFormValid: reducers.getIsEditBusinessUnitModalFormValid(state),
});

const mapDispatchToProps = {
  updateBusinessUnit: actions.updateBusinessUnit,
};

export default memo(
  connect(mapStateToProps, mapDispatchToProps)(EditBusinessUnitModal)
);
