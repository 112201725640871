// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  FETCH_GAQ_RELEASE_NOTES_FAILURE,
  FETCH_GAQ_RELEASE_NOTES_SUCCESS,
  FETCH_GAQ_RELEASE_NOTES,
  RELOAD_GAQ_RELEASE_NOTES_FAILURE,
  RELOAD_GAQ_RELEASE_NOTES_SUCCESS,
  RELOAD_GAQ_RELEASE_NOTES,
} from "../../../../actions";
import RD from "../../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case FETCH_GAQ_RELEASE_NOTES:
    case RELOAD_GAQ_RELEASE_NOTES:
      return RD.loading();

    case FETCH_GAQ_RELEASE_NOTES_SUCCESS:
    case RELOAD_GAQ_RELEASE_NOTES_SUCCESS:
      return RD.success(payload);

    case FETCH_GAQ_RELEASE_NOTES_FAILURE:
    case RELOAD_GAQ_RELEASE_NOTES_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
