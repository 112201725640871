// 9fbef606107a605d69c0edbcd8029e5d

import { identity, prop, sum } from "ramda";
import { createSelector } from "reselect";
import { DHL_REGIONS, MAP_REGIONS } from "../../utils/constants";

const PAGE_VIEWS = {
  AE: 35108,
  AF: 1072,
  AL: 4062,
  AO: 4712,
  AR: 24439,
  AT: 42685,
  AU: 98879,
  BA: 2545,
  BB: 1094,
  BD: 19593,
  BE: 31968,
  BG: 16324,
  BH: 5015,
  BJ: 1175,
  BM: 1743,
  BN: 1720,
  BO: 4514,
  BR: 80728,
  BS: 1145,
  BT: 355,
  BW: 725,
  BY: 1548,
  BZ: 278,
  CA: 155749,
  CD: 1201,
  CG: 368,
  CH: 45974,
  CI: 2070,
  CL: 29378,
  CM: 1729,
  CN: 340117,
  CO: 29820,
  CR: 5014,
  CW: 735,
  CY: 7888,
  CZ: 22020,
  DE: 163050,
  DK: 29752,
  DO: 7824,
  DZ: 6425,
  EC: 9665,
  EE: 7247,
  EG: 15603,
  ES: 137809,
  ET: 1791,
  FI: 26397,
  FJ: 1327,
  FR: 126749,
  G0: 52150,
  GA: 434,
  GB: 168643,
  GE: 5010,
  GF: 426,
  GH: 6474,
  GM: 394,
  GP: 460,
  GQ: 141,
  GR: 22281,
  GT: 5505,
  HK: 69738,
  HN: 2580,
  HR: 6938,
  HT: 1627,
  HU: 12848,
  ID: 26219,
  IE: 32252,
  IL: 18502,
  IN: 132889,
  IQ: 3369,
  IR: 1707,
  IS: 9631,
  IT: 202359,
  JM: 3501,
  JO: 4448,
  JP: 104633,
  KE: 4734,
  KH: 3295,
  KR: 84127,
  KW: 10908,
  KY: 1284,
  KZ: 9140,
  LA: 612,
  LB: 4728,
  LK: 5210,
  LS: 206,
  LT: 8986,
  LU: 4699,
  LV: 6378,
  MA: 8841,
  MG: 801,
  MK: 2179,
  ML: 405,
  MM: 1607,
  MN: 1620,
  MO: 1351,
  MQ: 376,
  MT: 6916,
  MU: 2632,
  MV: 1047,
  MW: 413,
  MX: 342468,
  MY: 38845,
  MZ: 1135,
  NA: 627,
  NG: 29164,
  NI: 2330,
  NL: 101373,
  NO: 37455,
  NP: 6264,
  NZ: 26795,
  OM: 8481,
  PA: 5095,
  PE: 18106,
  PF: 228,
  PG: 1083,
  PH: 27678,
  PK: 39050,
  PL: 43802,
  PT: 26108,
  PY: 2329,
  QA: 10142,
  RE: 952,
  RO: 22911,
  RS: 4381,
  RU: 15064,
  SA: 41477,
  SC: 670,
  SD: 813,
  SE: 88725,
  SG: 37665,
  SI: 5518,
  SK: 8283,
  SL: 494,
  SN: 2002,
  SS: 201,
  SV: 2720,
  SY: 2335,
  SZ: 245,
  TH: 25021,
  TL: 181,
  TR: 52233,
  TT: 2999,
  TW: 58728,
  TZ: 2283,
  UA: 16322,
  UG: 1926,
  US: 786958,
  UY: 4144,
  VC: 3362,
  VE: 8210,
  VG: 365,
  VN: 22676,
  YE: 768,
  ZA: 27355,
  ZM: 1779,
  ZW: 1024,
};

const INITIAL_STATE = Object.entries({
  AE: {
    coordinates: { lat: 23.4241, lng: 53.8478 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  AF: {
    coordinates: { lat: 33.9391, lng: 67.71 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  AL: {
    coordinates: { lat: 41.1533, lng: 20.1683 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  AN: {
    coordinates: { lat: 12.226, lng: -69.06 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  AO: {
    coordinates: { lat: -11.2027, lng: 17.8739 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  AR: {
    coordinates: { lat: -38.4161, lng: -63.6167 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  AT: {
    coordinates: { lat: 47.5162, lng: 14.5501 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  AU: {
    coordinates: { lat: -25.2744, lng: 133.7751 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.OCEANIA, MAP_REGIONS.OCEANIA_AUSTRALIA],
  },
  BA: {
    coordinates: { lat: 43.9159, lng: 17.6791 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  BB: {
    coordinates: { lat: 13.1939, lng: -59.5432 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  BD: {
    coordinates: { lat: 23.685, lng: 90.3563 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  BE: {
    coordinates: { lat: 50.5039, lng: 4.4699 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  BG: {
    coordinates: { lat: 42.7339, lng: 25.4858 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  BH: {
    coordinates: { lat: 26.0667, lng: 50.5577 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  BJ: {
    coordinates: { lat: 9.3077, lng: 2.3158 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  BM: {
    coordinates: { lat: 32.3078, lng: -64.7505 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_NORTH],
  },
  BN: {
    coordinates: { lat: 4.5353, lng: 114.7277 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  BO: {
    coordinates: { lat: -16.2902, lng: -63.5887 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  BR: {
    coordinates: { lat: -14.235, lng: -51.9253 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  BS: {
    coordinates: { lat: 25.0343, lng: -77.3963 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  BT: {
    coordinates: { lat: 27.5142, lng: 90.4336 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  BW: {
    coordinates: { lat: -22.3285, lng: 24.6849 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  BY: {
    coordinates: { lat: 53.7098, lng: 27.9534 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  BZ: {
    coordinates: { lat: 17.1899, lng: -88.4976 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  CA: {
    coordinates: { lat: 56.1304, lng: -106.3468 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_NORTH],
  },
  CD: {
    coordinates: { lat: -4.0383, lng: 21.7587 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  CG: {
    coordinates: { lat: -4.4419, lng: 15.2663 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  CH: {
    coordinates: { lat: 46.8182, lng: 8.2275 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  CI: {
    coordinates: { lat: 7.54, lng: -5.5471 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  CL: {
    coordinates: { lat: -35.6751, lng: -71.543 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  CM: {
    coordinates: { lat: 7.3697, lng: 12.3547 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  CN: {
    coordinates: { lat: 35.8617, lng: 104.1954 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  CO: {
    coordinates: { lat: -4.5709, lng: -74.2973 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  CR: {
    coordinates: { lat: 9.7489, lng: -83.7534 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  CW: {
    coordinates: { lat: 12.1696, lng: -68.99 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  CY: {
    coordinates: { lat: 35.1264, lng: 33.4299 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  CZ: {
    coordinates: { lat: 49.8175, lng: 15.473 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  DE: {
    coordinates: { lat: 51.1657, lng: 10.4515 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  DK: {
    coordinates: { lat: 56.2639, lng: 9.5018 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  DO: {
    coordinates: { lat: 18.7357, lng: -70.1627 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  DZ: {
    coordinates: { lat: 28.0339, lng: 1.6596 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_NOTHERN],
  },
  EC: {
    coordinates: { lat: -1.8312, lng: -78.1834 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  EE: {
    coordinates: { lat: 58.5953, lng: 25.0136 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  EG: {
    coordinates: { lat: 26.8206, lng: 30.8025 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_NOTHERN],
  },
  ES: {
    coordinates: { lat: 40.4637, lng: -3.7492 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  ET: {
    coordinates: { lat: 9.145, lng: 40.4897 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  FI: {
    coordinates: { lat: 61.9241, lng: 25.7482 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  FJ: {
    coordinates: { lat: -17.7134, lng: 173.065 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.OCEANIA, MAP_REGIONS.OCEANIA_MELANESIA],
  },
  FR: {
    coordinates: { lat: 46.2276, lng: 2.2137 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  GA: {
    coordinates: { lat: -0.8037, lng: 11.6094 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  GB: {
    coordinates: { lat: 55.3781, lng: -3.436 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  GE: {
    coordinates: { lat: 32.1656, lng: -82.9001 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  GF: {
    coordinates: { lat: 3.9339, lng: -53.1258 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  GH: {
    coordinates: { lat: 7.9465, lng: -1.0232 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  GM: {
    coordinates: { lat: 13.4432, lng: -15.3101 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  GP: {
    coordinates: { lat: 16.265, lng: -61.551 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  GQ: {
    coordinates: { lat: 1.6508, lng: 10.2679 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_MIDDLE],
  },
  GR: {
    coordinates: { lat: 39.0742, lng: 21.8243 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  GT: {
    coordinates: { lat: 15.7835, lng: -90.2308 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  HK: {
    coordinates: { lat: 22.3193, lng: 114.1694 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  HN: {
    coordinates: { lat: 15.2, lng: -86.2419 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  HR: {
    coordinates: { lat: 45.1, lng: 15.2 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  HT: {
    coordinates: { lat: 18.9712, lng: -72.2852 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  HU: {
    coordinates: { lat: 47.1625, lng: 19.5033 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  ID: {
    coordinates: { lat: -0.7893, lng: 113.9213 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  IE: {
    coordinates: { lat: 53.1424, lng: -7.6921 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  IL: {
    coordinates: { lat: 31.0461, lng: 34.8516 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  IN: {
    coordinates: { lat: 20.5937, lng: 78.9629 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  IQ: {
    coordinates: { lat: 33.2232, lng: 43.6793 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  IR: {
    coordinates: { lat: 32.4279, lng: 53.688 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  IS: {
    coordinates: { lat: 64.9631, lng: -19.0208 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  IT: {
    coordinates: { lat: 41.8719, lng: 12.5674 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  JM: {
    coordinates: { lat: 18.1096, lng: -77.2975 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  JO: {
    coordinates: { lat: 30.5852, lng: 36.2384 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  JP: {
    coordinates: { lat: 36.2048, lng: 138.2529 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  KE: {
    coordinates: { lat: -0.0236, lng: 37.9062 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  KH: {
    coordinates: { lat: 12.5657, lng: 104.991 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  KR: {
    coordinates: { lat: 35.9078, lng: 127.7669 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  KW: {
    coordinates: { lat: 29.3117, lng: 47.4818 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  KY: {
    coordinates: { lat: 19.3133, lng: -81.2546 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  KZ: {
    coordinates: { lat: 48.0196, lng: 66.9237 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_CENTRAL],
  },
  LA: {
    coordinates: { lat: 19.8563, lng: 102.4955 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  LB: {
    coordinates: { lat: 33.8547, lng: 35.8623 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  LK: {
    coordinates: { lat: 7.8731, lng: 80.7718 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  LS: {
    coordinates: { lat: -29.61, lng: 28.2336 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_SOUTHERN],
  },
  LT: {
    coordinates: { lat: 55.1694, lng: 23.8813 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  LU: {
    coordinates: { lat: 49.8153, lng: 6.1296 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  LV: {
    coordinates: { lat: 56.8796, lng: 24.6032 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  MA: {
    coordinates: { lat: 31.7917, lng: -7.0926 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_NOTHERN],
  },
  MG: {
    coordinates: { lat: -18.7669, lng: 46.8691 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  MK: {
    coordinates: { lat: 41.6086, lng: 21.7453 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  ML: {
    coordinates: { lat: 17.5707, lng: -3.9962 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  MM: {
    coordinates: { lat: 21.9162, lng: 95.956 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  MN: {
    coordinates: { lat: 46.8625, lng: 103.8467 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  MO: {
    coordinates: { lat: 22.1987, lng: 113.5439 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  MQ: {
    coordinates: { lat: 14.6415, lng: -61.0242 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  MT: {
    coordinates: { lat: 35.9375, lng: 14.3754 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  MU: {
    coordinates: { lat: -20.3484, lng: 57.5522 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  MV: {
    coordinates: { lat: 3.2028, lng: 73.2207 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  MW: {
    coordinates: { lat: -13.2543, lng: 34.3015 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  MX: {
    coordinates: { lat: 23.6345, lng: -102.5528 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  MY: {
    coordinates: { lat: 4.2105, lng: 101.9758 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  MZ: {
    coordinates: { lat: -18.6657, lng: 35.5296 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  NA: {
    coordinates: { lat: -22.9576, lng: 18.4904 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_SOUTHERN],
  },
  NG: {
    coordinates: { lat: 9.082, lng: 8.6753 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  NI: {
    coordinates: { lat: 12.8654, lng: -85.2072 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  NL: {
    coordinates: { lat: 52.1326, lng: 5.2913 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_WESTERN],
  },
  NO: {
    coordinates: { lat: 60.472, lng: 8.4689 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  NP: {
    coordinates: { lat: 28.3949, lng: 84.124 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  NZ: {
    coordinates: { lat: -40.9006, lng: 172.886 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.OCEANIA, MAP_REGIONS.OCEANIA_AUSTRALIA],
  },
  OM: {
    coordinates: { lat: 21.4735, lng: 55.9754 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  PA: {
    coordinates: { lat: 8.538, lng: -80.7821 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  PE: {
    coordinates: { lat: -9.19, lng: -75.0152 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  PG: {
    coordinates: { lat: -6.315, lng: 143.9555 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.OCEANIA, MAP_REGIONS.OCEANIA_MELANESIA],
  },
  PH: {
    coordinates: { lat: 12.8797, lng: 121.774 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  PK: {
    coordinates: { lat: 30.3753, lng: 69.3451 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTHERN],
  },
  PL: {
    coordinates: { lat: 51.9194, lng: 19.1451 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  PT: {
    coordinates: { lat: 39.3999, lng: -8.2245 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  PY: {
    coordinates: { lat: -23.4425, lng: -58.4438 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  QA: {
    coordinates: { lat: 25.3548, lng: 51.1839 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  RE: {
    coordinates: { lat: -21.1151, lng: 55.5364 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  RO: {
    coordinates: { lat: 45.9432, lng: 24.9668 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  RS: {
    coordinates: { lat: 44.0165, lng: 21.0059 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  RU: {
    coordinates: { lat: 61.524, lng: 105.3188 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  SA: {
    coordinates: { lat: 23.8859, lng: 45.0792 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  SC: {
    coordinates: { lat: -4.6796, lng: 55.492 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  SD: {
    coordinates: { lat: 12.8628, lng: 30.2176 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_NOTHERN],
  },
  SE: {
    coordinates: { lat: 60.1282, lng: 18.6435 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_NOTHERN],
  },
  SG: {
    coordinates: { lat: 1.3521, lng: 103.8198 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  SI: {
    coordinates: { lat: 46.1512, lng: 14.9955 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_SOUTHERN],
  },
  SK: {
    coordinates: { lat: 48.669, lng: 19.699 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  SL: {
    coordinates: { lat: 8.4606, lng: -11.7799 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  SN: {
    coordinates: { lat: 14.4974, lng: -14.4524 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_WESTERN],
  },
  SS: {
    coordinates: { lat: 6.877, lng: 31.307 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_NOTHERN],
  },
  SV: {
    coordinates: { lat: 13.7942, lng: -88.8965 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CENTRAL],
  },
  SY: {
    coordinates: { lat: 34.8021, lng: 38.9968 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  SZ: {
    coordinates: { lat: -26.5225, lng: 31.4659 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_SOUTHERN],
  },
  TH: {
    coordinates: { lat: 15.87, lng: 100.9925 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  TL: {
    coordinates: { lat: -8.8742, lng: 125.7275 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  TR: {
    coordinates: { lat: 38.9637, lng: 35.2433 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  TT: {
    coordinates: { lat: 10.6918, lng: -61.2225 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  TW: {
    coordinates: { lat: 23.6978, lng: 120.9605 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_EASTERN],
  },
  TZ: {
    coordinates: { lat: -6.369, lng: 34.8888 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  UA: {
    coordinates: { lat: 48.3794, lng: 31.1656 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.EUROPE, MAP_REGIONS.EUROPE_EASTERN],
  },
  UG: {
    coordinates: { lat: 1.3733, lng: 32.2903 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  US: {
    coordinates: { lat: 37.0902, lng: -95.7129 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_NORTH],
  },
  UY: {
    coordinates: { lat: -32.5228, lng: -55.7658 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  VE: {
    coordinates: { lat: 6.4238, lng: -66.5897 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_SOUTH],
  },
  VG: {
    coordinates: { lat: 18.4207, lng: -64.64 },
    dhlRegion: DHL_REGIONS.AMERICAS,
    regions: [MAP_REGIONS.AMERICA, MAP_REGIONS.AMERICA_CARIBBEAN],
  },
  VN: {
    coordinates: { lat: 14.0583, lng: 108.2772 },
    dhlRegion: DHL_REGIONS.APAC,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_SOUTH_EASTERN],
  },
  YE: {
    coordinates: { lat: 15.5527, lng: 48.5164 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.ASIA, MAP_REGIONS.ASIA_WESTERN],
  },
  ZA: {
    coordinates: { lat: -30.5595, lng: 22.9375 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_SOUTHERN],
  },
  ZM: {
    coordinates: { lat: -13.1339, lng: 27.8493 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
  ZW: {
    coordinates: { lat: -19.0154, lng: 29.1549 },
    dhlRegion: DHL_REGIONS.EUROPE_AND_MEA,
    regions: [MAP_REGIONS.AFRICA, MAP_REGIONS.AFRICA_EASTERN],
  },
}).reduce(
  (acc, [key, value]) => ({
    ...acc,
    [key]: { pageViews: PAGE_VIEWS[key] || 0, ...value },
  }),
  {}
);

const reducer = (state = INITIAL_STATE) => {
  return state;
};

export const getCoverages = createSelector(identity, (countries) => {
  const totalPageViews = sum(Object.values(countries).map(prop("pageViews")));

  return Object.entries(countries).reduce(
    (acc, [country, { pageViews }]) => ({
      ...acc,
      [country]: (pageViews / totalPageViews) * 100,
    }),
    {}
  );
});

export const getRegions = createSelector(identity, (countries) =>
  Object.entries(countries).reduce(
    (acc, [country, { regions }]) => ({
      ...acc,
      [country]: regions,
    }),
    {}
  )
);

export const getCoordinates = createSelector(identity, (countries) =>
  Object.entries(countries).reduce(
    (acc, [country, { coordinates }]) => ({
      ...acc,
      [country]: coordinates,
    }),
    {}
  )
);

export const getCoordinatesHashMap = createSelector(identity, (countries) =>
  Object.entries(countries).reduce(
    (acc, [country, { coordinates }]) => ({
      ...acc,
      [`${coordinates.lat}|${coordinates.lng}`]: country,
    }),
    {}
  )
);

export const getDHLRegion = createSelector(identity, (countries) =>
  Object.entries(countries).reduce(
    (acc, [country, { dhlRegion }]) => ({
      ...acc,
      [country]: dhlRegion,
    }),
    {}
  )
);

export default reducer;
