// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable no-underscore-dangle */

import { curry, propEq } from "ramda";

const DRAGGABLE = {
  OFF_DROPZONE: "OFF_DROPZONE",
  ON_DROPZONE: "ON_DROPZONE",
};

const NOOP = Function.prototype;

const Draggable = {
  offDropZone: (id, origin) => ({
    _data: {
      id,
      origin,
    },
    tag: DRAGGABLE.OFF_DROPZONE,
  }),

  onDropZone: (id, origin, dropZone) => ({
    _data: {
      id,
      origin,
      dropZone,
    },
    tag: DRAGGABLE.ON_DROPZONE,
  }),

  case: curry((caseObj, draggable) => {
    const { offDropZone, onDropZone, _ = NOOP } = caseObj;
    const { _data } = draggable;

    if (offDropZone && Draggable.isOffDropZone(draggable)) {
      return offDropZone(_data.id, _data.origin);
    }

    if (onDropZone && Draggable.isOnDropZone(draggable)) {
      return onDropZone(_data.id, _data.origin, _data.dropZone);
    }

    return _(_data.id, _data.origin);
  }),

  chain: curry((chainFun, draggable) =>
    Draggable.case(
      {
        offDropZone: (id, origin) => chainFun(id, origin),
        onDropZone: (id, origin) => chainFun(id, origin),
      },
      draggable
    )
  ),

  getId: (draggable) =>
    Draggable.case(
      {
        offDropZone: (id) => id,
        onDropZone: (id) => id,
      },
      draggable
    ),

  isOffDropZone: propEq("tag", DRAGGABLE.OFF_DROPZONE),

  isOnDropZone: propEq("tag", DRAGGABLE.ON_DROPZONE),
};

export default Draggable;
