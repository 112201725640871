// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import countriesReducer, * as countries from "./countries";
import metaReducer, * as meta from "./meta";

export default combineReducers({
  countries: countriesReducer,
  meta: metaReducer,
});

export const getCountries = (state) => countries.get(state.countries);

export const getMapDisplayMode = (state) => meta.getMapDisplayMode(state.meta);

export const getMapRegion = (state) => meta.getMapRegion(state.meta);

export const getReleaseStatus = (state) => meta.getReleaseStatus(state.meta);
