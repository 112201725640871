// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import RD from "../../../utils/functional/remote-data";
import {
  GET_BUSINESS_UNITS_FAILURE,
  GET_BUSINESS_UNITS_SUCCESS,
  GET_BUSINESS_UNITS,
} from "../../../actions";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_BUSINESS_UNITS:
      return RD.loading();

    case GET_BUSINESS_UNITS_SUCCESS: {
      const parcelAndDocuments = ["EXP", "PAR", "ECO"];
      const freight = ["FRE", "DGF"];
      const directMail = ["DPI"];

      return RD.success({
        parcelAndDocuments: payload.filter((item) =>
          parcelAndDocuments.includes(item)
        ),

        freight: payload.filter((item) => freight.includes(item)),

        directMail: payload.filter((item) => directMail.includes(item)),

        other: payload.filter(
          (item) =>
            ![...parcelAndDocuments, ...freight, ...directMail].includes(item)
        ),
      });
    }

    case GET_BUSINESS_UNITS_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export const get = identity;

export default reducer;
