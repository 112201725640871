// 9fbef606107a605d69c0edbcd8029e5d

import { compose, always, prop, propEq, not } from "ramda";
import RD from "../../../../utils/functional/remote-data";
import AC from "../../../../utils/functional/auto-complete";
import FF, { Meta as M } from "../../../../utils/functional/form-field";
import CS from "../../../../utils/functional/country-settings";
import D from "../../../../utils/functional/draggable";
import {
  DROP_DRAGGED_BUSINESS_UNIT,
  GET_FS_COUNTRY_CONFIGURATION_FAILURE,
  GET_FS_COUNTRY_CONFIGURATION_SUCCESS,
  GET_FS_COUNTRY_CONFIGURATION,
  GET_FS_COUNTRY_SETTINGS_FAILURE,
  GET_FS_COUNTRY_SETTINGS_SUCCESS,
  GET_FS_COUNTRY_SETTINGS,
  GET_RELEASED_COUNTRIES_FAILURE,
  GET_RELEASED_COUNTRIES_SUCCESS,
  GET_RELEASED_COUNTRIES,
  REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT,
  REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
  SAVE_COUNTRY_SETTINGS_SUCCESS,
  SELECT_FS_COUNTRY,
  SET_COUNTRY_SETTINGS_EDIT_MODE,
  SET_FS_COUNTRY,
  SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE,
  SET_NEW_COUNTRY_SETTINGS,
  START_DRAGGING_BUSINESS_UNIT,
  UPDATE_BUSINESS_UNIT,
} from "../../../../actions";
import { PRODUCTS } from "../../../../utils/constants";

const INITIAL_STATE = {
  countryField: AC.unselected("", []),
  configurations: {},
  draggedBusinessUnit: null,
  isEditMode: false,
  lastSelectedCountry: null,
  releasedCountries: RD.notAsked(),
  settings: {},
};

const moveBusinessUnit = (id, from, to, isCopy) => (settings) => {
  const toRegion = settings[to.product][to.region];

  if (from) {
    const fromRegion = settings[from.product][from.region];
    const fromBusinessUnit = fromRegion.find(propEq("id", id));
    const regionWithoutBU = fromRegion.filter(compose(not, propEq("id", id)));
    const regionWithBU = [
      ...toRegion,
      to.product === PRODUCTS.PARCEL
        ? fromBusinessUnit
        : { ...fromBusinessUnit, options: [] },
    ];

    if (isCopy) {
      return {
        ...settings,
        [to.product]: {
          ...settings[to.product],
          [to.region]: regionWithBU,
        },
      };
    }

    if (from.product === to.product) {
      return {
        ...settings,
        [from.product]: {
          ...settings[from.product],
          [to.region]: regionWithBU,
          [from.region]: regionWithoutBU,
        },
      };
    }

    return {
      ...settings,
      [from.product]: {
        ...settings[from.product],
        [from.region]: regionWithoutBU,
      },
      [to.product]: {
        ...settings[to.product],
        [to.region]: regionWithBU,
      },
    };
  }

  return {
    ...settings,
    [to.product]: {
      ...settings[to.product],
      [to.region]: [
        ...toRegion,
        { id, options: [], salesLeadQualified: true, selfOnBoardingUrls: [] },
      ],
    },
  };
};

const getCountryConfigurationId = (country, profile) =>
  profile ? `${country}-${profile}` : country;

const reducer = (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case START_DRAGGING_BUSINESS_UNIT:
      return {
        ...state,
        draggedBusinessUnit: D.offDropZone(payload.id, payload.origin),
      };

    case SET_DRAGGING_BUSINESS_UNIT_DROP_ZONE:
      return {
        ...state,
        draggedBusinessUnit: D.chain(
          (id, origin) => D.onDropZone(id, origin, payload),
          state.draggedBusinessUnit
        ),
      };

    case REMOVE_DRAGGING_BUSINESS_UNIT_DROP_ZONE:
      return {
        ...state,
        draggedBusinessUnit: D.isOnDropZone(state.draggedBusinessUnit)
          ? D.chain(D.offDropZone, state.draggedBusinessUnit)
          : state.draggedBusinessUnit,
      };

    case DROP_DRAGGED_BUSINESS_UNIT:
      return D.case(
        {
          onDropZone: (id, origin, dropZone) => {
            const updateSettings = compose(
              FF.chain(FF.unchecked),
              FF.map(moveBusinessUnit(id, origin, dropZone, payload))
            );

            return {
              ...state,
              draggedBusinessUnit: null,
              settings: {
                ...state.settings,
                [state.lastSelectedCountry]: CS.case(
                  {
                    existing: compose(CS.existing, RD.map(updateSettings)),
                    fresh: compose(CS.fresh, updateSettings),
                  },
                  state.settings[state.lastSelectedCountry]
                ),
              },
            };
          },

          _: always({
            ...state,
            draggedBusinessUnit: null,
          }),
        },
        state.draggedBusinessUnit
      );

    case UPDATE_BUSINESS_UNIT: {
      const { id, product, region, country } = payload.identity;
      const { threshold, options } = payload;

      const getThreshold = compose(parseFloat, FF.getValue);

      const updateBusinessUnit = (settings) => {
        const allBusinessUnits = settings[product][region];
        const businessUnit = allBusinessUnits.find(propEq("id", id));
        const indexOfBusinessUnit = allBusinessUnits.indexOf(businessUnit);
        const { fields } = threshold;

        const newBusinessUnit = {
          ...businessUnit,
          options: options || [],
          threshold: threshold.isEnabled
            ? {
                dailyShipments: getThreshold(fields.daily),
                monthlyShipments: getThreshold(fields.monthly),
                weeklyShipments: getThreshold(fields.weekly),
              }
            : undefined,
        };

        return {
          ...settings,
          [product]: {
            ...settings[product],
            [region]: [
              ...allBusinessUnits.slice(0, indexOfBusinessUnit),
              newBusinessUnit,
              ...allBusinessUnits.slice(indexOfBusinessUnit + 1),
            ],
          },
        };
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          [country]: CS.case(
            {
              existing: compose(
                CS.existing,
                RD.map(
                  compose(FF.chain(FF.unchecked), FF.map(updateBusinessUnit))
                )
              ),
              fresh: compose(
                CS.fresh,
                compose(FF.chain(FF.unchecked), FF.map(updateBusinessUnit))
              ),
            },
            state.settings[country]
          ),
        },
      };
    }

    case REMOVE_COUNTRY_SETTINGS_BUSINESS_UNIT: {
      const { id, product, region } = payload;

      const removeBusinessUnit = (settings) => ({
        ...settings,
        [product]: {
          ...settings[product],
          [region]: settings[product][region].filter(
            compose(not, propEq("id", id))
          ),
        },
      });

      return {
        ...state,
        settings: {
          ...state.settings,
          [state.lastSelectedCountry]: CS.case(
            {
              existing: compose(
                CS.existing,
                RD.map(
                  compose(FF.chain(FF.unchecked), FF.map(removeBusinessUnit))
                )
              ),
              fresh: compose(
                CS.fresh,
                compose(FF.chain(FF.unchecked), FF.map(removeBusinessUnit))
              ),
            },
            state.settings[state.lastSelectedCountry]
          ),
        },
      };
    }

    case GET_RELEASED_COUNTRIES:
      return {
        ...state,
        releasedCountries: RD.loading(),
      };

    case GET_RELEASED_COUNTRIES_SUCCESS:
      return {
        ...state,
        releasedCountries: RD.success(payload),
      };

    case GET_RELEASED_COUNTRIES_FAILURE:
      return {
        ...state,
        releasedCountries: RD.error(payload),
      };

    case SAVE_COUNTRY_SETTINGS_SUCCESS: {
      return {
        ...state,
        isEditMode: false,
        settings: {
          ...state.settings,
          [payload.country]: CS.existing(
            RD.success(
              FF.pristine(M.create(null, null), payload.countrySettings)
            )
          ),
        },
      };
    }

    case SET_COUNTRY_SETTINGS_EDIT_MODE:
      return {
        ...state,
        isEditMode: true,
      };

    case SET_FS_COUNTRY:
      return {
        ...state,
        countryField: payload,
      };

    case SELECT_FS_COUNTRY:
      return {
        ...state,
        countryField: AC.selected(payload, []),
        lastSelectedCountry: payload,
      };

    case SET_NEW_COUNTRY_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload]:
            state.settings[payload] ||
            CS.fresh(
              FF.pristine(M.create(null, null), {
                FREIGHT: {
                  DOMESTIC: [],
                  GLOBAL: [],
                  REGIONAL: [],
                },
                PARCEL: {
                  DOMESTIC: [],
                  GLOBAL: [],
                  REGIONAL: [],
                },
                MAIL: {
                  DOMESTIC: [],
                  GLOBAL: [],
                  REGIONAL: [],
                },
              })
            ),
        },
      };

    case GET_FS_COUNTRY_SETTINGS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload]: CS.existing(RD.loading()),
        },
      };

    case GET_FS_COUNTRY_SETTINGS_SUCCESS:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload.country]: CS.existing(
            RD.success(
              FF.pristine(M.create(null, null), payload.countrySettings)
            )
          ),
        },
      };

    case GET_FS_COUNTRY_SETTINGS_FAILURE:
      return {
        ...state,
        settings: {
          ...state.settings,
          [payload.country]: CS.existing(RD.error(payload.error)),
        },
      };

    case GET_FS_COUNTRY_CONFIGURATION:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          [getCountryConfigurationId(payload.country, payload.profile)]:
            RD.loading(),
        },
      };

    case GET_FS_COUNTRY_CONFIGURATION_SUCCESS:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          [getCountryConfigurationId(payload.country, payload.profile)]:
            RD.success(payload.countryConfiguration),
        },
      };

    case GET_FS_COUNTRY_CONFIGURATION_FAILURE:
      return {
        ...state,
        configurations: {
          ...state.configurations,
          [getCountryConfigurationId(payload.country, payload.profile)]:
            RD.error(payload.error),
        },
      };

    default:
      return state;
  }
};

export const getReleasedCountries = prop("releasedCountries");

export const getBusinessUnits = prop("businessUnits");

export const getIsEditMode = prop("isEditMode");

export const getCountryField = prop("countryField");

export const getDraggedBusinessUnit = prop("draggedBusinessUnit");

export const getLastSelectedCountry = prop("lastSelectedCountry");

export const getCountryConfiguration = (state, country, profile) =>
  state.configurations[getCountryConfigurationId(country, profile)];

export const getCountrySettings = (state, country) => state.settings[country];

export default reducer;
