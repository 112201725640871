import React from "react";
import { render } from "react-dom";
import { IntlProvider } from "react-intl";
import { Provider } from "react-redux";
import { createBrowserHistory } from "history";
import Entry from "./components";
import createStore from "./store";
import ResetStyle from "./styles";
import copy from "./assets/copy/index.json";
import { SESSION_ACCESS_KEY } from "./utils/constants";

const history = createBrowserHistory();

const store = createStore(history, sessionStorage.getItem(SESSION_ACCESS_KEY));

render(
  <IntlProvider locale="en" messages={copy}>
    <Provider store={store}>
      <ResetStyle />
      <Entry history={history} />
    </Provider>
  </IntlProvider>,
  document.getElementById(process.env.MOUNT_ID)
);
