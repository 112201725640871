import React, { memo, Fragment } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { THEME, Text } from "@dhl-official/react-ui-library";
import Applications from "../../../../utils/functional/applications";
import Flags from "../../../Flags";
import COPY from "../../../../assets/copy/index.json";
import RolloutStatus from "./RolloutStatus";

// #region Component Styles
const Container = styled.div`
  min-width: 400px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
`;

const CountryInfo = styled.div``;

const Coverage = styled(Text).attrs({
  isParagraph: true,
  size: Text.TINY,
})`
  color: ${THEME.getGrayColor("600")};
  margin: 0;
  margin-top: -3px;
`;
// #endregion

const CJMapRegionModal = ({ rolledOutCountry }) => {
  // Shared information
  const { country, coverage } = Applications.getValue(rolledOutCountry);

  return (
    <Container>
      <Title>
        <Flags country={country} />{" "}
        <CountryInfo>
          {COPY[`Country.${country}`]}
          {coverage > 0 && <Coverage>{coverage.toFixed(2)}% Coverage</Coverage>}
        </CountryInfo>
      </Title>

      {Applications.case(
        {
          all: (data) => {
            const { frequentShipment, getAQuote } = data.applications;

            return (
              <Fragment>
                <RolloutStatus
                  application="FREQUENT SHIPMENT"
                  releases={frequentShipment.releases}
                  status={frequentShipment.status}
                />

                <RolloutStatus
                  application="GET A QUOTE"
                  releases={getAQuote.releases}
                  status={getAQuote.status}
                />
              </Fragment>
            );
          },

          frequentShipment: (data) => (
            <RolloutStatus
              application="FREQUENT SHIPMENT"
              releases={data.releases}
              status={data.status}
            />
          ),

          getAQuote: (data) => (
            <RolloutStatus
              application="GET A QUOTE"
              releases={data.releases}
              status={data.status}
            />
          ),
        },
        rolledOutCountry
      )}
    </Container>
  );
};

CJMapRegionModal.propTypes = {
  rolledOutCountry: PropTypes.object.isRequired,
};

export default memo(CJMapRegionModal);
