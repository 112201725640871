// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";

const INITIAL_STATE = [
  "AE",
  "AL",
  "AO",
  "AR",
  "AT",
  "AU",
  "BA",
  "BB",
  "BD",
  "BE",
  "BG",
  "BH",
  "BJ",
  "BM",
  "BN",
  "BO",
  "BR",
  "BS",
  "BT",
  "BW",
  "BZ",
  "CA",
  "CD",
  "CG",
  "CH",
  "CI",
  "CL",
  "CM",
  "CN",
  "CO",
  "CR",
  "CY",
  "CZ",
  "DE",
  "DK",
  "DO",
  "DZ",
  "EC",
  "EE",
  "EG",
  "ES",
  "ET",
  "FI",
  "FJ",
  "FR",
  "GA",
  "GB",
  "GF",
  "GH",
  "GM",
  "GP",
  "GQ",
  "GR",
  "GT",
  "HK",
  "HN",
  "HR",
  "HT",
  "HU",
  "ID",
  "IE",
  "IL",
  "IN",
  "IQ",
  "IS",
  "IT",
  "JM",
  "JO",
  "JP",
  "KE",
  "KH",
  "KR",
  "KW",
  "KY",
  "KZ",
  "LA",
  "LB",
  "LK",
  "LS",
  "LT",
  "LU",
  "LV",
  "MA",
  "MG",
  "MK",
  "ML",
  "MM",
  "MO",
  "MQ",
  "MT",
  "MU",
  "MV",
  "MW",
  "MX",
  "MY",
  "MZ",
  "NA",
  "NG",
  "NI",
  "NL",
  "NO",
  "NP",
  "NZ",
  "OM",
  "PA",
  "PE",
  "PG",
  "PH",
  "PK",
  "PL",
  "PT",
  "PY",
  "QA",
  "RE",
  "RO",
  "RS",
  "SA",
  "SC",
  "SD",
  "SE",
  "SG",
  "SI",
  "SK",
  "SL",
  "SN",
  "SS",
  "SV",
  "SY",
  "SZ",
  "TH",
  "TL",
  "TR",
  "TT",
  "TW",
  "TZ",
  "UA",
  "UG",
  "US",
  "UY",
  "VE",
  "VG",
  "VN",
  "YE",
  "ZA",
  "ZM",
  "ZW",
];

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    default:
      return state;
  }
};

export default reducer;

export const get = identity;
