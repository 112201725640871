import { FormattedDate } from "react-intl";
import React, { memo } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { THEME, Text } from "@dhl-official/react-ui-library";
import Flags from "../../../Flags";
import COPY from "../../../../assets/copy/index.json";
import { getColorForStatus } from "../../../../utils";
import { CURRENT_DATE, HOMEPAGE_VERSIONS } from "../../../../utils/constants";

// #region Component Styles
const Container = styled.div`
  min-width: 400px;
`;

const Title = styled.div`
  align-items: center;
  display: flex;
`;

const CountryInfo = styled.div``;

const Coverage = styled(Text).attrs({
  isParagraph: true,
  size: Text.TINY,
})`
  color: ${THEME.getGrayColor("600")};
  margin: 0;
  margin-top: -3px;
`;

const Version = styled(Text).attrs({
  isParagraph: true,
  weight: 700,
  size: Text.TINY,
})`
  margin: 0;
  margin-top: ${THEME.getUnit("regular")};
`;

const ReleaseList = styled.ul``;

const Release = styled.li`
  margin-top: ${THEME.getUnit("small")};

  :first-of-type {
    margin-top: 0;
  }
`;

const Info = styled(Text).attrs({ isParagraph: true })`
  margin: 0;
`;

const Locales = styled.span`
  color: ${THEME.getGrayColor("700")};
`;

const Highlight = styled(Text).attrs({
  size: Text.BIG,
  isParagraph: true,
})`
  color: ${({ status }) => getColorForStatus(status)};
  margin: 0;
  margin-top: -4px;
`;
// #endregion

const getLanguages = (languages) =>
  languages.map((l) => COPY[`Language.${l}`]).join(", ");

const CJMapRegionModal = ({ rolledOutCountry }) => {
  const { country, coverage, releases, status, version } = rolledOutCountry;

  return (
    <Container>
      <Title>
        <Flags country={country} />{" "}
        <CountryInfo>
          {COPY[`Country.${country}`]}
          {coverage > 0 && <Coverage>{coverage.toFixed(2)}% Coverage</Coverage>}
        </CountryInfo>
      </Title>

      <Version>
        {version === HOMEPAGE_VERSIONS.FULL
          ? "FULL VERSION"
          : "TRACKING CENTERED VERSION"}
      </Version>

      <ReleaseList>
        {[...releases.sort((a, b) => a.date - b.date)].map(
          ({ date, languages }) => (
            <Release key={date}>
              <Info>
                <Locales>({getLanguages(languages)})</Locales>{" "}
                {date <= CURRENT_DATE ? "Released" : "Planned Release"} on
              </Info>{" "}
              <Highlight status={status}>
                <FormattedDate
                  day="numeric"
                  month="long"
                  value={date}
                  weekday="long"
                  year="numeric"
                />
              </Highlight>
            </Release>
          )
        )}
      </ReleaseList>
    </Container>
  );
};

CJMapRegionModal.propTypes = {
  rolledOutCountry: PropTypes.object.isRequired,
};

export default memo(CJMapRegionModal);
