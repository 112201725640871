// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  SIGN_IN,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAILURE,
  SIGN_OUT,
} from "../../../actions";
import { SESSION_ACCESS_KEY } from "../../../utils/constants";

const signOut = () => ({
  type: SIGN_OUT,
});

const signIn = (username, password) => ({
  payload: { username, password },
  type: SIGN_IN,
});

const signInSuccess = (payload) => ({
  payload,
  type: SIGN_IN_SUCCESS,
});

const signInFailure = (error) => ({
  error: true,
  payload: error,
  type: SIGN_IN_FAILURE,
});

export const signInEffect =
  (username, password) =>
  (dispatch, _, { api, history, storage }) => {
    dispatch(signIn(username, password));

    api
      .signIn(username, password)
      .then((res) => (res.status === 200 ? res.json() : Promise.reject()))
      .then(({ access_token: token }) => {
        if (history.location.state && history.location.state.redirectUrl) {
          history.push(history.location.state.redirectUrl);
        }

        storage.setItem(SESSION_ACCESS_KEY, token);

        dispatch(signInSuccess(token));
      })
      .catch(compose(dispatch, signInFailure));
  };

export const signOutEffect =
  () =>
  (dispatch, _, { storage }) => {
    storage.removeItem(SESSION_ACCESS_KEY);
    dispatch(signOut());
  };
