import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import { THEME, Text } from "@dhl-official/react-ui-library";
import Flags from "../Flags";
import COPY from "../../assets/copy/index.json";

// #region Component Styles
const TextLabel = styled(Text)`
  background-color: white;
  border-radius: ${THEME.getRadius()};
  box-shadow: rgb(229 229 229) 0px 3px 8px 0px;
  left: 50%;
  margin-left: -3px;
  opacity: 0;
  padding: ${THEME.getUnit("small")} ${THEME.getUnit("regular")};
  pointer-events: none;
  position: absolute;
  top: -45px;
  transform: translate(-50%, 0);
  transition: opacity ${THEME.getAnimationSpeed("regular")};
  white-space: nowrap;

  &:before {
    background-color: transparent;
    border-left: 10px solid transparent;
    border-right: 10px solid transparent;
    border-top: 10px solid white;
    bottom: -10px;
    content: " ";
    filter: drop-shadow(1px 1px 1px rgb(229 229 229));
    height: 0;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    width: 0;
  }
`;

const Container = styled.div`
  position: relative;
  cursor: pointer;

  &:hover {
    ${TextLabel} {
      opacity: 1;
    }
  }
`;
// #endregion

const LabelFlag = ({ country }) => (
  <Container>
    <TextLabel>{COPY[`Country.${country}`]}</TextLabel>
    <Flags country={country} />
  </Container>
);

LabelFlag.propTypes = {
  country: PropTypes.string.isRequired,
};

export default LabelFlag;
