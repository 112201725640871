// 9fbef606107a605d69c0edbcd8029e5d

import { prop } from "ramda";
import {
  SET_HOMEPAGE_MAP_DISPLAY_MODE,
  SET_HOMEPAGE_MAP_REGION,
  TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER,
} from "../../../../actions";
import {
  MAP_DISPLAY_MODES,
  MAP_REGIONS,
  HOMEPAGE_RELEASE_STATUS,
} from "../../../../utils/constants";

const INITIAL_STATE = {
  mapDisplayMode: MAP_DISPLAY_MODES.REGION,
  mapRegion: MAP_REGIONS.WORLD,
  releaseStatus: Object.values(HOMEPAGE_RELEASE_STATUS),
};

const reducer = (state = INITIAL_STATE, action) => {
  const { payload } = action;

  switch (action.type) {
    case SET_HOMEPAGE_MAP_DISPLAY_MODE:
      return {
        ...state,
        mapDisplayMode: payload,
      };

    case SET_HOMEPAGE_MAP_REGION:
      return {
        ...state,
        mapRegion: payload,
      };

    case TOGGLE_HOMEPAGE_ROLLOUT_STATUS_FILTER:
      return {
        ...state,
        releaseStatus: state.releaseStatus.includes(payload)
          ? state.releaseStatus.filter((a) => a !== payload)
          : [...state.releaseStatus, payload],
      };

    default:
      return state;
  }
};

export default reducer;

export const getMapDisplayMode = prop("mapDisplayMode");

export const getMapRegion = prop("mapRegion");

export const getReleaseStatus = prop("releaseStatus");
