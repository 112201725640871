// 9fbef606107a605d69c0edbcd8029e5d

import { always, identity } from "ramda";
import { HOMEPAGE_VERSIONS } from "../../../../utils/constants";

const INITIAL_STATE = [
  {
    country: "AR",
    releases: [
      { date: new Date(2020, 10, 4), languages: ["es"] },
      { date: new Date(2020, 8, 9), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "AU",
    releases: [{ date: new Date(2020, 2, 18), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "AT",
    releases: [
      { date: new Date(2020, 3, 29), languages: ["de"] },
      { date: new Date(2020, 2, 11), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "BE",
    releases: [
      { date: new Date(2021, 1, 17), languages: ["fr", "nl"] },
      { date: new Date(2020, 10, 25), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "BR",
    releases: [
      { date: new Date(2020, 3, 29), languages: ["pt"] },
      { date: new Date(2020, 2, 11), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CA",
    releases: [
      { date: new Date(2020, 3, 22), languages: ["fr"] },
      { date: new Date(2020, 2, 4), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CL",
    releases: [
      { date: new Date(2020, 10, 18), languages: ["es"] },
      { date: new Date(2020, 8, 23), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CN",
    releases: [
      { date: new Date(2020, 3, 15), languages: ["zh"] },
      { date: new Date(2020, 1, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CO",
    releases: [
      { date: new Date(2020, 3, 15), languages: ["es"] },
      { date: new Date(2020, 1, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CZ",
    releases: [
      { date: new Date(2020, 9, 7), languages: ["cs"] },
      { date: new Date(2020, 7, 12), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "DK",
    releases: [
      { date: new Date(2020, 9, 28), languages: ["da"] },
      { date: new Date(2020, 8, 2), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "FI",
    releases: [
      { date: new Date(2020, 9, 21), languages: ["fi"] },
      { date: new Date(2020, 7, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "FR",
    releases: [
      { date: new Date(2020, 3, 8), languages: ["fr"] },
      { date: new Date(2020, 1, 19), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "DE",
    releases: [
      { date: new Date(2020, 10, 4), languages: ["de"] },
      { date: new Date(2020, 8, 9), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "GB",
    releases: [{ date: new Date(2019, 10, 27), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "GR",
    releases: [
      { date: new Date(2020, 10, 18), languages: ["el"] },
      { date: new Date(2020, 8, 23), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "HK",
    releases: [
      { date: new Date(2021, 1, 3), languages: ["zh"] },
      { date: new Date(2020, 10, 25), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "IN",
    releases: [{ date: new Date(2019, 10, 27), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "IE",
    releases: [{ date: new Date(2020, 8, 30), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "IT",
    releases: [
      { date: new Date(2020, 3, 15), languages: ["it"] },
      { date: new Date(2020, 1, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "JP",
    releases: [
      { date: new Date(2020, 3, 22), languages: ["ja"] },
      { date: new Date(2020, 2, 4), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "KR",
    releases: [
      { date: new Date(2020, 3, 22), languages: ["ko"] },
      { date: new Date(2020, 2, 4), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "MY",
    releases: [{ date: new Date(2020, 2, 18), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "MX",
    releases: [{ date: new Date(2019, 10, 27), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },
  {
    country: "NL",
    releases: [
      { date: new Date(2020, 10, 4), languages: ["nl"] },
      { date: new Date(2020, 8, 9), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "NZ",
    releases: [{ date: new Date(2020, 8, 16), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "NO",
    releases: [
      { date: new Date(2020, 9, 7), languages: ["no"] },
      { date: new Date(2020, 7, 12), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "PK",
    releases: [{ date: new Date(2020, 8, 16), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "PE",
    releases: [
      { date: new Date(2020, 9, 14), languages: ["es"] },
      { date: new Date(2020, 7, 19), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "PL",
    releases: [
      { date: new Date(2020, 3, 29), languages: ["pl"] },
      { date: new Date(2020, 2, 11), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "PT",
    releases: [
      { date: new Date(2020, 3, 8), languages: ["pt"] },
      { date: new Date(2020, 1, 19), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "RO",
    releases: [
      { date: new Date(2020, 9, 28), languages: ["ro"] },
      { date: new Date(2020, 8, 2), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "SA",
    releases: [{ date: new Date(2020, 7, 19), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "SG",
    releases: [{ date: new Date(2020, 8, 16), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "ZA",
    releases: [{ date: new Date(2020, 2, 18), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "ES",
    releases: [
      { date: new Date(2020, 3, 8), languages: ["es"] },
      { date: new Date(2020, 1, 19), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "SE",
    releases: [
      { date: new Date(2020, 9, 21), languages: ["sv"] },
      { date: new Date(2020, 7, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "RU",
    releases: [
      { date: new Date(2020, 5, 11), languages: ["en"] },
      { date: new Date(2020, 10, 18), languages: ["ru"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "CH",
    releases: [
      { date: new Date(2021, 2, 3), languages: ["de", "fr", "it"] },
      { date: new Date(2020, 10, 25), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "TW",
    releases: [
      { date: new Date(2020, 9, 7), languages: ["zh"] },
      { date: new Date(2020, 7, 12), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "TH",
    releases: [
      { date: new Date(2020, 9, 21), languages: ["th"] },
      { date: new Date(2020, 7, 26), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "TR",
    releases: [
      { date: new Date(2020, 9, 14), languages: ["tr"] },
      { date: new Date(2020, 7, 19), languages: ["en"] },
    ],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "US",
    releases: [{ date: new Date(2020, 2, 18), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.FULL,
  },

  {
    country: "VN",
    releases: [{ date: new Date(2021, 4, 17), languages: ["en", "vi"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "PA",
    releases: [{ date: new Date(2021, 3, 29), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "UA",
    releases: [{ date: new Date(2021, 4, 4), languages: ["en", "uk"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SI",
    releases: [{ date: new Date(2021, 4, 4), languages: ["en", "sl"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SK",
    releases: [
      { date: new Date(2021, 3, 14), languages: ["en"] },
      { date: new Date(2021, 4, 17), languages: ["sk"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "RS",
    releases: [{ date: new Date(2021, 4, 17), languages: ["en", "sr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LT",
    releases: [
      { date: new Date(2021, 3, 8), languages: ["en"] },
      { date: new Date(2021, 4, 25), languages: ["lt"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LV",
    releases: [
      { date: new Date(2021, 3, 21), languages: ["en"] },
      { date: new Date(2021, 4, 25), languages: ["lv"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "HU",
    releases: [
      { date: new Date(2021, 3, 22), languages: ["en"] },
      { date: new Date(2021, 4, 5), languages: ["hu"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "EE",
    releases: [
      { date: new Date(2021, 3, 8), languages: ["en"] },
      { date: new Date(2021, 4, 19), languages: ["ee"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "HR",
    releases: [
      { date: new Date(2021, 3, 8), languages: ["en"] },
      { date: new Date(2021, 4, 5), languages: ["hr"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BG",
    releases: [
      { date: new Date(2021, 3, 14), languages: ["en"] },
      { date: new Date(2021, 4, 21), languages: ["bg"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "UY",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "PY",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "NI",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "HN",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GQ",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SV",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "EC",
    releases: [{ date: new Date(2021, 3, 28), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MA",
    releases: [{ date: new Date(2021, 3, 28), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "DO",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CR",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BO",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BZ",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MQ",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GT",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "es"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SC",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SN",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "RE",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MZ",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "pt"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "ML",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MG",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LU",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "de", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "KZ",
    releases: [
      { date: new Date(2021, 3, 12), languages: ["en"] },
      { date: new Date(2021, 3, 13), languages: ["ru"] },
    ],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "HT",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GF",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CG",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GP",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GA",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CY",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en", "el"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CI",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CM",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BJ",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BY",
    releases: [{ date: new Date(2021, 3, 13), languages: ["en", "ru"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "AO",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "pt"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CD",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "DZ",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en", "fr"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "AL",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "AF",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "ZW",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "ZM",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "YE",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "VG",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "VE",
    releases: [{ date: new Date(2021, 3, 28), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "AE",
    releases: [{ date: new Date(2021, 4, 17), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SY",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SZ",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SD",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LK",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SS",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "OM",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "NG",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "CW",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "NP",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "NA",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "UG",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "TT",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "TZ",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MO",
    releases: [{ date: new Date(2021, 2, 23), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LS",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "SL",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "QA",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "PH",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "PG",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "KE",
    releases: [{ date: new Date(2021, 4, 5), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MM",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MU",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MT",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MV",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MW",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LB",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "LA",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "KW",
    releases: [{ date: new Date(2021, 4, 5), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "ET",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "EG",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "JO",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "JM",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "IQ",
    releases: [{ date: new Date(2021, 3, 1), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "IR",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BN",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "ID",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GH",
    releases: [{ date: new Date(2021, 4, 17), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GE",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "GM",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "FJ",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "TL",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "KY",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "KH",
    releases: [{ date: new Date(2021, 2, 24), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "IS",
    releases: [{ date: new Date(2021, 2, 25), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MN",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BW",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BT",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BM",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BB",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "MK",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BD",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BH",
    releases: [{ date: new Date(2021, 4, 5), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BS",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "IL",
    releases: [{ date: new Date(2021, 4, 5), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },

  {
    country: "BA",
    releases: [{ date: new Date(2021, 2, 26), languages: ["en"] }],
    version: HOMEPAGE_VERSIONS.LIGHT,
  },
];

const reducer = always(INITIAL_STATE);

export default reducer;

export const get = identity;
