import React, { useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import {
  Headline,
  THEME,
  Button,
  InputField,
  INPUTFIELD_LABEL_TYPES,
} from "@dhl-official/react-ui-library";
import { MESSAGE_DISPLAY_DURATION } from "../../../utils/constants";

// #region Component Styles
const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: 0;
    opacity: 1;
  }
`;

const Container = styled.div``;

const Title = styled(Headline).attrs({
  designLevel: 3,
  tag: Headline.H3,
})`
  color: ${({ shouldHighlight }) =>
    shouldHighlight
      ? THEME.getPrimaryColor("400")
      : THEME.getFontColor("regular")};

  animation: ${THEME.getAnimationSpeed("regular")} ease-in-out 0s forwards
    ${slideIn};
`;

const Content = styled.div`
  display: flex;
`;

const ReadOnlyUrlField = styled(InputField).attrs({
  id: "share-modal-input",
  isBlock: true,
  variant: {
    placeholder: "Share URL",
    type: INPUTFIELD_LABEL_TYPES.ANIMATED,
  },
})`
  width: 400px;
`;

const CopyButton = styled(Button).attrs({
  variant: Button.SECONDARY,
})`
  margin-left: ${THEME.getUnit("regular")};
`;
// #endregion

const ShareModal = ({ url }) => {
  const interval = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (!interval.current) {
      setIsCopied(true);
      interval.current = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(interval.current);
        interval.current = null;
      }, MESSAGE_DISPLAY_DURATION);
    }
  };

  return (
    <Container>
      {isCopied ? (
        <Title shouldHighlight key={1}>
          Link Copied!
        </Title>
      ) : (
        <Title key={2}>Copy Link To Clipboard</Title>
      )}

      <Content>
        <ReadOnlyUrlField value={url} />
        <CopyButton onClick={copyToClipboard}>Copy Link</CopyButton>
      </Content>
    </Container>
  );
};

ShareModal.propTypes = {
  url: PropTypes.string.isRequired,
};

export default memo(ShareModal);
