import React from "react";
import PropTypes from "prop-types";
import { hot } from "react-hot-loader/root";
import { Router, Switch, Route } from "react-router-dom";
import AsyncRoute from "./AsyncRoute";
import Application from "./Application";
import SignIn from "./SignIn/container";
import { ROUTES } from "../utils/constants";

const Entry = ({ history }) => (
  <Router history={history}>
    <Switch>
      <Route exact path={ROUTES.SIGN_IN} component={SignIn} />
      <AsyncRoute component={Application} />
    </Switch>
  </Router>
);

Entry.propTypes = {
  history: PropTypes.object.isRequired,
};

export default hot(Entry);
