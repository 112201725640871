// 9fbef606107a605d69c0edbcd8029e5d

import { css } from "styled-components";

const getFlagForCountry = (country) => {
  switch (country) {
    case "AD":
      return css`
        background-position: -42px 0px;
      `;

    case "AE":
      return css`
        background-position: -462px -210px;
      `;

    case "AF":
      return css`
        background-position: 0px -42px;
      `;

    case "AG":
      return css`
        background-position: -42px -42px;
      `;

    case "AI":
      return css`
        background-position: -84px 0px;
      `;

    case "AL":
      return css`
        background-position: -84px -42px;
      `;

    case "AM":
      return css`
        background-position: 0px -84px;
      `;

    case "AN":
      return css`
        background-position: -42px -84px;
      `;

    case "AO":
      return css`
        background-position: -84px -84px;
      `;

    case "AQ":
      return css`
        background-position: -126px 0px;
      `;

    case "AR":
      return css`
        background-position: -126px -42px;
      `;

    case "AS":
      return css`
        background-position: -126px -84px;
      `;

    case "AT":
      return css`
        background-position: 0px -126px;
      `;

    case "AU":
      return css`
        background-position: -42px -126px;
      `;

    case "AW":
      return css`
        background-position: -84px -126px;
      `;

    case "AX":
      return css`
        background-position: -126px -126px;
      `;

    case "AZ":
      return css`
        background-position: -168px 0px;
      `;

    case "BA":
      return css`
        background-position: -168px -42px;
      `;

    case "BB":
      return css`
        background-position: -168px -84px;
      `;

    case "BD":
      return css`
        background-position: -168px -126px;
      `;

    case "BE":
      return css`
        background-position: 0px -168px;
      `;

    case "BF":
      return css`
        background-position: -42px -168px;
      `;

    case "BG":
      return css`
        background-position: -84px -168px;
      `;

    case "BH":
      return css`
        background-position: -126px -168px;
      `;

    case "BI":
      return css`
        background-position: -168px -168px;
      `;

    case "BJ":
      return css`
        background-position: -210px 0px;
      `;

    case "BL":
      return css`
        background-position: -210px -42px;
      `;

    case "BM":
      return css`
        background-position: -210px -84px;
      `;

    case "BN":
      return css`
        background-position: -210px -126px;
      `;

    case "BO":
      return css`
        background-position: -210px -168px;
      `;

    case "BR":
      return css`
        background-position: 0px -210px;
      `;

    case "BS":
      return css`
        background-position: -42px -210px;
      `;

    case "BT":
      return css`
        background-position: -84px -210px;
      `;

    case "BW":
      return css`
        background-position: -126px -210px;
      `;

    case "BY":
      return css`
        background-position: -168px -210px;
      `;

    case "BZ":
      return css`
        background-position: -210px -210px;
      `;

    case "CA":
      return css`
        background-position: -252px 0px;
      `;

    case "CC":
      return css`
        background-position: -252px -42px;
      `;

    case "CD":
      return css`
        background-position: -252px -84px;
      `;

    case "CF":
      return css`
        background-position: -252px -126px;
      `;

    case "CG":
      return css`
        background-position: -252px -168px;
      `;

    case "CH":
      return css`
        background-position: -252px -210px;
      `;

    case "CI":
      return css`
        background-position: 0px -252px;
      `;

    case "CK":
      return css`
        background-position: -42px -252px;
      `;

    case "CL":
      return css`
        background-position: -84px -252px;
      `;

    case "CM":
      return css`
        background-position: -126px -252px;
      `;

    case "CN":
      return css`
        background-position: -168px -252px;
      `;

    case "CO":
      return css`
        background-position: -210px -252px;
      `;

    case "CR":
      return css`
        background-position: -252px -252px;
      `;

    case "CU":
      return css`
        background-position: -294px 0px;
      `;

    case "CV":
      return css`
        background-position: -294px -42px;
      `;

    case "CW":
      return css`
        background-position: -294px -84px;
      `;

    case "CX":
      return css`
        background-position: -294px -126px;
      `;

    case "CY":
      return css`
        background-position: -294px -168px;
      `;

    case "CZ":
      return css`
        background-position: -294px -210px;
      `;

    case "DE":
      return css`
        background-position: -294px -252px;
      `;

    case "DJ":
      return css`
        background-position: 0px -294px;
      `;

    case "DK":
      return css`
        background-position: -42px -294px;
      `;

    case "DM":
      return css`
        background-position: -84px -294px;
      `;

    case "DO":
      return css`
        background-position: -126px -294px;
      `;

    case "DZ":
      return css`
        background-position: -168px -294px;
      `;

    case "EC":
      return css`
        background-position: -210px -294px;
      `;

    case "EE":
      return css`
        background-position: -252px -294px;
      `;

    case "EG":
      return css`
        background-position: -294px -294px;
      `;

    case "EH":
      return css`
        background-position: -336px 0px;
      `;

    case "ER":
      return css`
        background-position: -336px -42px;
      `;

    case "ES":
      return css`
        background-position: -336px -84px;
      `;

    case "ET":
      return css`
        background-position: -336px -126px;
      `;

    case "EU":
      return css`
        background-position: -336px -168px;
      `;

    case "FI":
      return css`
        background-position: -336px -210px;
      `;

    case "FJ":
      return css`
        background-position: -336px -252px;
      `;

    case "FK":
      return css`
        background-position: -336px -294px;
      `;

    case "FM":
      return css`
        background-position: 0px -336px;
      `;

    case "FO":
      return css`
        background-position: -42px -336px;
      `;

    case "FR":
      return css`
        background-position: -84px -336px;
      `;

    case "GA":
      return css`
        background-position: -126px -336px;
      `;

    case "GB":
      return css`
        background-position: -168px -336px;
      `;

    case "GD":
      return css`
        background-position: -210px -336px;
      `;

    case "GE":
      return css`
        background-position: -252px -336px;
      `;

    case "GF":
      return css`
        background-position: -294px -336px;
      `;

    case "GG":
      return css`
        background-position: -336px -336px;
      `;

    case "GH":
      return css`
        background-position: -378px 0px;
      `;

    case "GI":
      return css`
        background-position: -378px -42px;
      `;

    case "GL":
      return css`
        background-position: -378px -84px;
      `;

    case "GM":
      return css`
        background-position: -378px -126px;
      `;

    case "GN":
      return css`
        background-position: -378px -168px;
      `;

    case "GP":
      return css`
        background-position: -378px -210px;
      `;

    case "GQ":
      return css`
        background-position: -378px -252px;
      `;

    case "GR":
      return css`
        background-position: -378px -294px;
      `;

    case "GS":
      return css`
        background-position: -378px -336px;
      `;

    case "GT":
      return css`
        background-position: 0px -378px;
      `;

    case "GU":
      return css`
        background-position: -42px -378px;
      `;

    case "GW":
      return css`
        background-position: -84px -378px;
      `;

    case "GY":
      return css`
        background-position: -126px -378px;
      `;

    case "HK":
      return css`
        background-position: -168px -378px;
      `;

    case "HN":
      return css`
        background-position: -210px -378px;
      `;

    case "HR":
      return css`
        background-position: -252px -378px;
      `;

    case "HT":
      return css`
        background-position: -294px -378px;
      `;

    case "HU":
      return css`
        background-position: -336px -378px;
      `;

    case "IC":
      return css`
        background-position: -378px -378px;
      `;

    case "ID":
      return css`
        background-position: -420px 0px;
      `;

    case "IE":
      return css`
        background-position: -420px -42px;
      `;

    case "IL":
      return css`
        background-position: -420px -84px;
      `;

    case "IM":
      return css`
        background-position: -420px -126px;
      `;

    case "IN":
      return css`
        background-position: -420px -168px;
      `;

    case "IQ":
      return css`
        background-position: -420px -210px;
      `;

    case "IR":
      return css`
        background-position: -420px -252px;
      `;

    case "IS":
      return css`
        background-position: -420px -294px;
      `;

    case "IT":
      return css`
        background-position: -420px -336px;
      `;

    case "JE":
      return css`
        background-position: -420px -378px;
      `;

    case "JM":
      return css`
        background-position: 0px -420px;
      `;

    case "JO":
      return css`
        background-position: -42px -420px;
      `;

    case "JP":
      return css`
        background-position: -84px -420px;
      `;

    case "KE":
      return css`
        background-position: -126px -420px;
      `;

    case "KG":
      return css`
        background-position: -168px -420px;
      `;

    case "KH":
      return css`
        background-position: -210px -420px;
      `;

    case "KI":
      return css`
        background-position: -252px -420px;
      `;

    case "KM":
      return css`
        background-position: -294px -420px;
      `;

    case "KN":
      return css`
        background-position: -336px -420px;
      `;

    case "KP":
      return css`
        background-position: -378px -420px;
      `;

    case "KR":
      return css`
        background-position: -420px -420px;
      `;

    case "KV":
      return css`
        background-position: -462px 0px;
      `;

    case "KW":
      return css`
        background-position: -462px -42px;
      `;

    case "KY":
      return css`
        background-position: -462px -84px;
      `;

    case "KZ":
      return css`
        background-position: -462px -126px;
      `;

    case "LA":
      return css`
        background-position: -462px -168px;
      `;

    case "LB":
      return css`
        background-position: 0px 0px;
      `;

    case "LC":
      return css`
        background-position: -462px -252px;
      `;

    case "LI":
      return css`
        background-position: -462px -294px;
      `;

    case "LK":
      return css`
        background-position: -462px -336px;
      `;

    case "LR":
      return css`
        background-position: -462px -378px;
      `;

    case "LS":
      return css`
        background-position: -462px -420px;
      `;

    case "LT":
      return css`
        background-position: 0px -462px;
      `;

    case "LU":
      return css`
        background-position: -42px -462px;
      `;

    case "LV":
      return css`
        background-position: -84px -462px;
      `;

    case "LY":
      return css`
        background-position: -126px -462px;
      `;

    case "MA":
      return css`
        background-position: -168px -462px;
      `;

    case "MC":
      return css`
        background-position: -210px -462px;
      `;

    case "MD":
      return css`
        background-position: -252px -462px;
      `;

    case "ME":
      return css`
        background-position: -294px -462px;
      `;

    case "MF":
      return css`
        background-position: -336px -462px;
      `;

    case "MG":
      return css`
        background-position: -378px -462px;
      `;

    case "MH":
      return css`
        background-position: -420px -462px;
      `;

    case "MK":
      return css`
        background-position: -462px -462px;
      `;

    case "ML":
      return css`
        background-position: -504px 0px;
      `;

    case "MM":
      return css`
        background-position: -504px -42px;
      `;

    case "MN":
      return css`
        background-position: -504px -84px;
      `;

    case "MO":
      return css`
        background-position: -504px -126px;
      `;

    case "MP":
      return css`
        background-position: -504px -168px;
      `;

    case "MQ":
      return css`
        background-position: -504px -210px;
      `;

    case "MR":
      return css`
        background-position: -504px -252px;
      `;

    case "MS":
      return css`
        background-position: -504px -294px;
      `;

    case "MT":
      return css`
        background-position: -504px -336px;
      `;

    case "MU":
      return css`
        background-position: -504px -378px;
      `;

    case "MV":
      return css`
        background-position: -504px -420px;
      `;

    case "MW":
      return css`
        background-position: -504px -462px;
      `;

    case "MX":
      return css`
        background-position: 0px -504px;
      `;

    case "MY":
      return css`
        background-position: -42px -504px;
      `;

    case "MZ":
      return css`
        background-position: -84px -504px;
      `;

    case "NA":
      return css`
        background-position: -126px -504px;
      `;

    case "NC":
      return css`
        background-position: -168px -504px;
      `;

    case "NE":
      return css`
        background-position: -210px -504px;
      `;

    case "NF":
      return css`
        background-position: -252px -504px;
      `;

    case "NG":
      return css`
        background-position: -294px -504px;
      `;

    case "NI":
      return css`
        background-position: -336px -504px;
      `;

    case "NL":
      return css`
        background-position: -378px -504px;
      `;

    case "NO":
      return css`
        background-position: -420px -504px;
      `;

    case "NP":
      return css`
        background-position: -462px -504px;
      `;

    case "NR":
      return css`
        background-position: -504px -504px;
      `;

    case "NU":
      return css`
        background-position: -546px 0px;
      `;

    case "NZ":
      return css`
        background-position: -546px -42px;
      `;

    case "OM":
      return css`
        background-position: -546px -84px;
      `;

    case "PA":
      return css`
        background-position: -546px -126px;
      `;

    case "PE":
      return css`
        background-position: -546px -168px;
      `;

    case "PF":
      return css`
        background-position: -546px -210px;
      `;

    case "PG":
      return css`
        background-position: -546px -252px;
      `;

    case "PH":
      return css`
        background-position: -546px -294px;
      `;

    case "PK":
      return css`
        background-position: -546px -336px;
      `;

    case "PL":
      return css`
        background-position: -546px -378px;
      `;

    case "PN":
      return css`
        background-position: -546px -420px;
      `;

    case "PR":
      return css`
        background-position: -546px -462px;
      `;

    case "PS":
      return css`
        background-position: -546px -504px;
      `;

    case "PT":
      return css`
        background-position: 0px -546px;
      `;

    case "PW":
      return css`
        background-position: -42px -546px;
      `;

    case "PY":
      return css`
        background-position: -84px -546px;
      `;

    case "QA":
      return css`
        background-position: -126px -546px;
      `;

    case "RE":
      return css`
        background-position: -168px -546px;
      `;

    case "RO":
      return css`
        background-position: -210px -546px;
      `;

    case "RS":
      return css`
        background-position: -252px -546px;
      `;

    case "RU":
      return css`
        background-position: -294px -546px;
      `;

    case "RW":
      return css`
        background-position: -336px -546px;
      `;

    case "SA":
      return css`
        background-position: -378px -546px;
      `;

    case "SB":
      return css`
        background-position: -420px -546px;
      `;

    case "SC":
      return css`
        background-position: -462px -546px;
      `;

    case "SD":
      return css`
        background-position: -504px -546px;
      `;

    case "SE":
      return css`
        background-position: -546px -546px;
      `;

    case "SG":
      return css`
        background-position: -588px 0px;
      `;

    case "SH":
      return css`
        background-position: -588px -42px;
      `;

    case "SI":
      return css`
        background-position: -588px -84px;
      `;

    case "SK":
      return css`
        background-position: -588px -126px;
      `;

    case "SL":
      return css`
        background-position: -588px -168px;
      `;

    case "SM":
      return css`
        background-position: -588px -210px;
      `;

    case "SN":
      return css`
        background-position: -588px -252px;
      `;

    case "SO":
      return css`
        background-position: -588px -294px;
      `;

    case "SR":
      return css`
        background-position: -588px -336px;
      `;

    case "SS":
      return css`
        background-position: -588px -378px;
      `;

    case "ST":
      return css`
        background-position: -588px -420px;
      `;

    case "SV":
      return css`
        background-position: -588px -462px;
      `;

    case "SY":
      return css`
        background-position: -588px -504px;
      `;

    case "SZ":
      return css`
        background-position: -588px -546px;
      `;

    case "TC":
      return css`
        background-position: 0px -588px;
      `;

    case "TD":
      return css`
        background-position: -42px -588px;
      `;

    case "TF":
      return css`
        background-position: -84px -588px;
      `;

    case "TG":
      return css`
        background-position: -126px -588px;
      `;

    case "TH":
      return css`
        background-position: -168px -588px;
      `;

    case "TJ":
      return css`
        background-position: -210px -588px;
      `;

    case "TK":
      return css`
        background-position: -252px -588px;
      `;

    case "TL":
      return css`
        background-position: -294px -588px;
      `;

    case "TM":
      return css`
        background-position: -336px -588px;
      `;

    case "TN":
      return css`
        background-position: -378px -588px;
      `;

    case "TO":
      return css`
        background-position: -420px -588px;
      `;

    case "TR":
      return css`
        background-position: -462px -588px;
      `;

    case "TT":
      return css`
        background-position: -504px -588px;
      `;

    case "TV":
      return css`
        background-position: -546px -588px;
      `;

    case "TW":
      return css`
        background-position: -588px -588px;
      `;

    case "TZ":
      return css`
        background-position: -630px 0px;
      `;

    case "UA":
      return css`
        background-position: -630px -42px;
      `;

    case "UG":
      return css`
        background-position: -630px -84px;
      `;

    case "US":
      return css`
        background-position: -630px -126px;
      `;

    case "UY":
      return css`
        background-position: -630px -168px;
      `;

    case "UZ":
      return css`
        background-position: -630px -210px;
      `;

    case "VA":
      return css`
        background-position: -630px -252px;
      `;

    case "VC":
      return css`
        background-position: -630px -294px;
      `;

    case "VE":
      return css`
        background-position: -630px -336px;
      `;

    case "VG":
      return css`
        background-position: -630px -378px;
      `;

    case "VI":
      return css`
        background-position: -630px -420px;
      `;

    case "VN":
      return css`
        background-position: -630px -462px;
      `;

    case "VU":
      return css`
        background-position: -630px -504px;
      `;

    case "WF":
      return css`
        background-position: -630px -546px;
      `;

    case "WS":
      return css`
        background-position: -630px -588px;
      `;

    case "XB":
      return css`
        background-position: 0px -630px;
      `;

    case "XC":
      return css`
        background-position: -42px -630px;
      `;

    case "XE":
      return css`
        background-position: -84px -630px;
      `;

    case "XM":
      return css`
        background-position: -126px -630px;
      `;

    case "XN":
      return css`
        background-position: -168px -630px;
      `;

    case "XS":
      return css`
        background-position: -505px -636px;
      `;

    case "XY":
      return css`
        background-position: -210px -630px;
      `;

    case "YE":
      return css`
        background-position: -252px -630px;
      `;

    case "YT":
      return css`
        background-position: -294px -630px;
      `;

    case "ZA":
      return css`
        background-position: -336px -630px;
      `;

    case "ZM":
      return css`
        background-position: -378px -630px;
      `;

    case "ZW":
      return css`
        background-position: -420px -630px;
      `;

    default:
      return css`
        background-position: -462px -630px;
      `;
  }
};

export default getFlagForCountry;
