import { identity } from "ramda";
import RD from "../../../../utils/functional/remote-data";
import {
  GET_FS_CONNECTORS_OVERVIEW,
  GET_FS_CONNECTORS_OVERVIEW_SUCCESS,
  GET_FS_CONNECTORS_OVERVIEW_FAILURE,
} from "../../../../actions";
import { CONNECTORS_BUSINESS_UNITS } from "../../../../utils/constants";

const INITIAL_STATE = {
  config: RD.notAsked(),
  releaseDates: {
    BB: new Date(2024, 3, 10),
    GQ: new Date(2024, 3, 10),
    IS: new Date(2024, 3, 10),
    MT: new Date(2024, 3, 10),
    YE: new Date(2024, 3, 10),
    BW: new Date(2024, 3, 23),
    HT: new Date(2024, 3, 23),
    MO: new Date(2024, 3, 23),
    MW: new Date(2024, 3, 23),
    SY: new Date(2024, 3, 23),
    AE: new Date(2022, 7, 8),
    AL: new Date(2023, 10, 7),
    AO: new Date(2023, 10, 7),
    AR: new Date(2022, 9, 17),
    AT: new Date(2022, 6, 11),
    AU: new Date(2022, 5, 13),
    BA: new Date(2023, 10, 16),
    BD: new Date(2022, 5, 27),
    BE: new Date(2023, 4, 30),
    BG: new Date(2022, 7, 8),
    BH: new Date(2023, 9, 25),
    BJ: new Date(2024, 7, 13),
    BM: new Date(2024, 1, 20),
    BN: new Date(2024, 0, 16),
    BO: new Date(2023, 8, 8),
    BR: new Date(2022, 9, 17),
    BS: new Date(2024, 1, 20),
    BT: new Date(2024, 1, 20),
    BZ: new Date(2024, 1, 27),
    CA: new Date(2022, 9, 17),
    CD: new Date(2024, 2, 26),
    CG: new Date(2024, 6, 30),
    CH: new Date(2022, 3, 6),
    CI: new Date(2023, 10, 8),
    CL: new Date(2022, 9, 31),
    CM: new Date(2023, 10, 16),
    CN: new Date(2023, 8, 8),
    CO: new Date(2022, 9, 17),
    CR: new Date(2024, 5, 4),
    CY: new Date(2023, 9, 24),
    CZ: new Date(2022, 7, 8),
    DE: new Date(2023, 8, 28),
    DK: new Date(2022, 6, 25),
    DO: new Date(2024, 0, 16),
    DZ: new Date(2024, 4, 21),
    EC: new Date(2023, 9, 24),
    EE: new Date(2022, 7, 22),
    EG: new Date(2022, 9, 4),
    ES: new Date(2022, 6, 11),
    ET: new Date(2023, 9, 25),
    FI: new Date(2022, 6, 25),
    FJ: new Date(2023, 10, 8),
    FR: new Date(2022, 3, 6),
    GA: new Date(2023, 10, 8),
    GB: new Date(2022, 3, 6),
    GF: new Date(2024, 2, 26),
    GH: new Date(2023, 1, 6),
    GM: new Date(2024, 6, 30),
    GP: new Date(2024, 5, 4),
    GR: new Date(2022, 6, 11),
    GT: new Date(2023, 9, 25),
    HK: new Date(2022, 5, 13),
    HN: new Date(2023, 9, 24),
    HR: new Date(2022, 5, 27),
    HU: new Date(2022, 7, 8),
    ID: new Date(2022, 5, 27),
    IE: new Date(2022, 3, 6),
    IL: new Date(2022, 7, 8),
    IN: new Date(2022, 4, 30),
    IQ: new Date(2023, 10, 7),
    IT: new Date(2022, 3, 6),
    JM: new Date(2024, 0, 16),
    JO: new Date(2024, 0, 16),
    JP: new Date(2022, 7, 22),
    KE: new Date(2023, 1, 6),
    KH: new Date(2023, 9, 24),
    KR: new Date(2022, 4, 30),
    KW: new Date(2022, 9, 4),
    KY: new Date(2024, 1, 20),
    KZ: new Date(2022, 7, 22),
    LA: new Date(2024, 1, 20),
    LB: new Date(2024, 5, 18),
    LK: new Date(2022, 5, 27),
    LS: new Date(2024, 2, 26),
    LT: new Date(2022, 6, 11),
    LU: new Date(2024, 5, 4),
    LV: new Date(2022, 7, 22),
    MA: new Date(2023, 9, 24),
    MG: new Date(2023, 10, 16),
    MK: new Date(2024, 6, 16),
    ML: new Date(2024, 6, 30),
    MQ: new Date(2024, 1, 20),
    MM: new Date(2024, 5, 18),
    MU: new Date(2023, 10, 7),
    MV: new Date(2024, 1, 20),
    MX: new Date(2022, 9, 17),
    MY: new Date(2022, 3, 6),
    MZ: new Date(2023, 10, 16),
    NA: new Date(2024, 6, 30),
    NG: new Date(2023, 1, 6),
    NI: new Date(2023, 10, 16),
    NO: new Date(2022, 5, 27),
    NP: new Date(2024, 0, 16),
    NZ: new Date(2021, 6, 21),
    OM: new Date(2023, 9, 25),
    PA: new Date(2023, 10, 7),
    PE: new Date(2022, 9, 31),
    PG: new Date(2023, 10, 8),
    PH: new Date(2022, 3, 6),
    PK: new Date(2022, 5, 13),
    PL: new Date(2022, 6, 25),
    PT: new Date(2022, 6, 25),
    PY: new Date(2024, 1, 20),
    QA: new Date(2023, 9, 9),
    RE: new Date(2024, 1, 27),
    RO: new Date(2022, 3, 6),
    RS: new Date(2024, 7, 13),
    SA: new Date(2022, 9, 4),
    SE: new Date(2022, 6, 11),
    SG: new Date(2021, 6, 21),
    SI: new Date(2024, 5, 18),
    SK: new Date(2022, 7, 8),
    SL: new Date(2024, 6, 30),
    SN: new Date(2023, 10, 16),
    SV: new Date(2023, 9, 9),
    SZ: new Date(2024, 7, 13),
    TH: new Date(2022, 3, 6),
    TL: new Date(2024, 1, 27),
    TR: new Date(2022, 6, 25),
    TT: new Date(2024, 1, 20),
    TW: new Date(2022, 3, 6),
    TZ: new Date(2024, 5, 18),
    UA: new Date(2024, 5, 18),
    UG: new Date(2023, 9, 9),
    US: new Date(2022, 6, 25),
    UY: new Date(2024, 0, 16),
    VE: new Date(2023, 9, 25),
    VG: new Date(2024, 1, 27),
    VN: new Date(2022, 4, 30),
    ZA: new Date(2023, 1, 6),
    ZM: new Date(2024, 7, 13),
    ZW: new Date(2023, 10, 8),
  },
};

/**
 * Let's reorder the config API answer a bit
 * We want to have an array with entries that consist of the
 * respective country and BU ids and the Connector option ids only,
 * the rest we don't need
 */
const processConnectorsOverviewData = (data) =>
  data.reduce((acc, entry) => {
    if (entry?.config?.featureToggles?.showConnectors) {
      const connectorsData = entry.config.featureToggles.showConnectors;
      const entriesPerCountry = [];
      Object.keys(connectorsData).forEach((key) => {
        if (CONNECTORS_BUSINESS_UNITS.includes(key)) {
          entriesPerCountry.push({
            country: entry.countryCode,
            businessUnit: key,
            connectors: Object.keys(connectorsData[key]),
          });
        }
      });
      return [...acc, ...entriesPerCountry];
    }
    return acc;
  }, []);

const reducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_FS_CONNECTORS_OVERVIEW:
      return {
        ...state,
        config: RD.loading(),
      };

    case GET_FS_CONNECTORS_OVERVIEW_SUCCESS:
      return {
        ...state,
        config: RD.success(
          processConnectorsOverviewData(action.payload.connectorsOverview)
        ),
      };

    case GET_FS_CONNECTORS_OVERVIEW_FAILURE:
      return {
        ...state,
        config: RD.error(action.payload.error),
      };

    default:
      return state;
  }
};

export const getConnectorsOverviewConfig = (state) => state.config;

export const getConnectorsOverviewReleaseDates = (state) => state.releaseDates;

export default reducer;

export const get = identity;
