import React, { memo, useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Button, IconChevron, THEME } from "@dhl-official/react-ui-library";

// #region Component Styles
const Container = styled(Button).attrs({
  variant: Button.PRIMARY,
  type: Button.BUTTON,
})`
  display: block;
  bottom: 0;
  margin: ${THEME.getUnit("large")};
  padding: ${THEME.getUnit("small")} 2px;
  position: fixed;
  right: 0;
  transition: opacity ${THEME.getAnimationSpeed("regular")},
    transform ${THEME.getAnimationSpeed("regular")};

  ${({ isvisible }) =>
    isvisible === "true"
      ? css`
          opacity: 1;
          transform: translateY(0);
        `
      : css`
          opacity: 0;
          transform: translateY(10px);
        `}
`;

const Icon = styled(IconChevron)`
  padding: 0 7px;
  width: 22px;
  height: 22px;
  transform: rotateZ(90deg) translateX(2px);
`;
// #endregion

const BackToTop = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const scrollListener = () => {
      const { scrollY } = window;

      if (scrollY > 60) {
        setIsVisible(true);
        return;
      }

      setIsVisible(false);
    };

    window.addEventListener("scroll", scrollListener);

    return () => window.removeEventListener("scroll", scrollListener);
  }, []);

  return (
    <Container
      isvisible={isVisible ? "true" : "false"}
      onClick={() => {
        document.body.scrollIntoView({ behavior: "smooth" });
        document.activeElement.blur();
      }}
      tabIndex={isVisible ? 0 : -1}
      title="Scroll page back to top"
    >
      <Icon />
    </Container>
  );
};

export default memo(BackToTop);
