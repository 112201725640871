// 9fbef606107a605d69c0edbcd8029e5d

import { pluck } from "ramda";
import { normalizeURL } from "..";

const getCurrentTimestamp = () => new Date().getMilliseconds();

const decodeAnalyticsNumbers = (input) =>
  Object.entries(input).reduce(
    (acc, [key, value]) => ({
      ...acc,
      [key]: {
        ...value,
        businessUnits: Object.entries(value.businessUnits).reduce(
          (bus, [bu, data]) => [...bus, { id: bu, data }],
          []
        ),
      },
    }),
    {}
  );

const decodeFSLeadNumbers = (input) => ({
  ...input,
  leadNumbers: input.leadNumbers.reduce(
    (acc, value) => ({
      ...acc,
      [value.country]: {
        ...value,
        businessUnits: value.businessUnits.reduce(
          (bus, bu) => [
            ...bus,
            {
              id: bu.id,
              data: bu.totalLeads,
            },
          ],
          []
        ),
        total: value.businessUnits.reduce(
          (total, bu) => total + bu.totalLeads,
          0
        ),
      },
    }),
    {}
  ),
});

// react-hook-form doesn't support flat arrays
// we need to convert the languages and features into array of objects
// before temporarily saving into the state
// then undo it before we POST it
const arrToFormObj = (arr, keyName) =>
  arr.map((item) => ({
    [keyName]: item,
  }));

const objToArr = (arr, keyName) => arr.map((item) => item[keyName]);

// This is a temporary solution until react-hook-form supports flat arrays
// and we can have multi-select option fields for languages so we don't
// have to change the back-end contract and the static release notes for FS and SN
const decodeDataFromApi = (data, operation) => {
  const tempData = data;
  if (typeof data.date === "string") {
    tempData.date = new Date(data.date);
  }
  if (data.features) {
    const tempFeatures = operation(data.features, "feature");
    tempData.features = tempFeatures;
  }
  if (data.countries) {
    const tempCountries = data.countries.map((country) => ({
      ...country,
      languages: operation(country.languages, "language"),
    }));
    tempData.countries = tempCountries;
  }
  return tempData;
};

// Converts date string to Date for DatePicker and CJ Rollout Map
const stringToDate = (data) => {
  const tempData = data;
  tempData.map((release) => {
    if (typeof release.date === "string") {
      /* eslint no-param-reassign: ["error", { "props": true, "ignorePropertyModificationsFor": ["release"] }] */
      release.date = new Date(release.date);
    }
    return release;
  });
  return tempData;
};

const fetchGAQLeadNumbers = (accessToken) =>
  fetch(
    normalizeURL(`${process.env.API_BASE_URL}/api/cj/analytics/leadValues`),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then(decodeAnalyticsNumbers);

const fetchFSLeadNumbers = (accessToken) =>
  fetch(
    normalizeURL(`${process.env.API_BASE_URL}/api/cj/analytics/leadNumbersV2`),
    {
      headers: {
        Authorization: `Bearer ${accessToken}`,
      },
    }
  )
    .then((res) => res.json())
    .then(decodeFSLeadNumbers);

const getReleasedCountries = () =>
  fetch(
    normalizeURL(`${process.env.API_BASE_URL}/api/cj/admin/countries`)
  ).then((res) => res.json());

const getBusinessUnits = () =>
  fetch(normalizeURL(`${process.env.API_BASE_URL}/api/cj/admin/businessUnits`))
    .then((res) => res.json())
    .then(pluck("code"));

const saveCountrySettings = (accessToken, countrySettings, method) =>
  fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/cj/admin/businessLeadConfiguration`
    ),
    {
      body: JSON.stringify(countrySettings),
      method,
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => res.json());

const signIn = (username, password) =>
  fetch(normalizeURL(`${process.env.AUTH_BASE_URL}/api/auth/authenticate`), {
    body: JSON.stringify({
      username,
      password,
    }),
    headers: {
      "Content-Type": "application/json",
    },
    method: "POST",
  });

const getFSCountryConfiguration = (country, profile) => {
  const profileExtension = profile ? `/profile/${profile}` : "";
  return fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/configuration/v2/fs/${country}${profileExtension}`
    )
  ).then((res) => res.json());
};

const getFSCountrySettings = (country) =>
  fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/cj/admin/businessLeadConfiguration/${country}`
    )
  ).then((res) => res.json());

const getFSConnectorsOverview = () => {
  return fetch(
    normalizeURL(`${process.env.API_BASE_URL}/api/configuration/v2/fs/all`)
  ).then((res) => res.json());
};

const getGAQCountrySettings = (country) =>
  fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/configuration/v2/ots/${country}`
    )
  ).then((res) => res.json());

/*
 For the time being, it will be just Get a Quote
 In future this must be updated to point to different apps
*/

const fetchGAQReleaseNotes = () =>
  fetch(
    normalizeURL(
      `${
        process.env.API_BASE_URL
      }/api/cj/admin/gaq/release-notes?t=${getCurrentTimestamp()}`
    )
  )
    .then((res) => res.json())
    .then((res) => stringToDate(res));

const getGAQReleaseNote = (id) =>
  fetch(
    normalizeURL(
      `${
        process.env.API_BASE_URL
      }/api/cj/admin/gaq/release-notes/${id}?t=${getCurrentTimestamp()}`
    )
  )
    .then((res) => res.json())
    .then((res) => decodeDataFromApi(res, arrToFormObj));

const deleteGAQReleaseNote = (id, accessToken) =>
  fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/cj/admin/gaq/release-notes/${id}`
    ),
    {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  );

const saveGAQReleaseNote = (accessToken, releaseNote) =>
  fetch(
    normalizeURL(`${process.env.API_BASE_URL}/api/cj/admin/gaq/release-notes`),
    {
      body: JSON.stringify(decodeDataFromApi(releaseNote, objToArr)),
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => res.json());

const updateGAQReleaseNote = (accessToken, id, releaseNote) =>
  fetch(
    normalizeURL(
      `${process.env.API_BASE_URL}/api/cj/admin/gaq/release-notes/${id}`
    ),
    {
      body: JSON.stringify(decodeDataFromApi(releaseNote, objToArr)),
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${accessToken}`,
      },
    }
  ).then((res) => res.json());

export default {
  fetchFSLeadNumbers,
  fetchGAQLeadNumbers,
  getBusinessUnits,
  getFSCountryConfiguration,
  getFSCountrySettings,
  getFSConnectorsOverview,
  getGAQCountrySettings,
  getReleasedCountries,
  saveCountrySettings,
  signIn,
  fetchGAQReleaseNotes,
  getGAQReleaseNote,
  saveGAQReleaseNote,
  updateGAQReleaseNote,
  deleteGAQReleaseNote,
};
