import { compose } from "ramda";
import * as reducers from "../../..";
import RD from "../../../../utils/functional/remote-data";
import {
  GET_FS_CONNECTORS_OVERVIEW,
  GET_FS_CONNECTORS_OVERVIEW_SUCCESS,
  GET_FS_CONNECTORS_OVERVIEW_FAILURE,
} from "../../../../actions";

const getConnectorsOverview = () => ({
  type: GET_FS_CONNECTORS_OVERVIEW,
});

const getConnectorsOverviewSuccess = () => (connectorsOverview) => ({
  payload: { connectorsOverview },
  type: GET_FS_CONNECTORS_OVERVIEW_SUCCESS,
});

const getConnectorsOverviewFailure = () => (error) => ({
  error: true,
  payload: { error },
  type: GET_FS_CONNECTORS_OVERVIEW_FAILURE,
});

export const getFSConnectorsOverviewEffect =
  () =>
  (dispatch, getState, { api }) => {
    const connectorsOverview = reducers.getFSConnectorsOverviewConfig(
      getState()
    );

    if (
      connectorsOverview &&
      (RD.isSuccess(connectorsOverview) || RD.isLoading(connectorsOverview))
    ) {
      return;
    }

    dispatch(getConnectorsOverview());

    api
      .getFSConnectorsOverview()
      .then(compose(dispatch, getConnectorsOverviewSuccess()))
      .catch(compose(dispatch, getConnectorsOverviewFailure()));
  };
