// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  SIGN_IN,
  SIGN_IN_FAILURE,
  SIGN_IN_SUCCESS,
  SIGN_OUT,
} from "../../../actions";
import RD from "../../../utils/functional/remote-data";

const reducer =
  (initialState) =>
  (state = initialState ? RD.success(initialState) : RD.notAsked(), action) => {
    const { type, payload } = action;

    switch (type) {
      case SIGN_IN:
        return RD.loading();

      case SIGN_IN_SUCCESS:
        return RD.success(payload);

      case SIGN_IN_FAILURE:
        return RD.error(payload);

      case SIGN_OUT:
        return RD.notAsked();

      default:
        return state;
    }
  };

export const get = identity;

export default reducer;
