import React from "react";
import { FormattedMessage } from "react-intl";
import PropTypes from "prop-types";
import {
  Button,
  Headline,
  IconChevron,
  Text,
  THEME,
} from "@dhl-official/react-ui-library";
import styled from "styled-components";
import Threshold from "./Threshold/container";
import Urgency from "./Urgency/container";

// #region Component Styles
const Container = styled.div`
  max-width: 400px;
`;

const BusinessUnit = styled(Headline).attrs({
  designLevel: 4,
  tag: Headline.H4,
  noMargin: true,
})`
  padding-right: 40px;
`;

const Breadcrumb = styled(Text).attrs({ isParagraph: true })`
  margin: 0;
  color: ${THEME.getGrayColor("700")};
`;

const ArrowIcon = styled(IconChevron)`
  color: ${THEME.getPrimaryColor("400")};
  display: inline-block;
  height: 12px;
  margin: 0 3px;
  margin-bottom: -1px;
  transform: rotateZ(0.5turn);
  width: 12px;
`;

const Instructions = styled(Text).attrs({ isParagraph: true })`
  margin: ${THEME.getUnit("regular")} 0;
`;

const ThresholdContainer = styled.div``;

const UrgencyContainer = styled.div`
  margin-top: ${THEME.getUnit("large")};
`;

const ActionButtons = styled.div`
  margin-top: calc(${THEME.getUnit("large")} + ${THEME.getUnit("regular")});
`;

const UpdateButton = styled(Button).attrs({ variant: Button.PRIMARY })`
  margin-right: ${THEME.getUnit("regular")};
`;
// #endregion

const EditBusinessUnitModal = ({
  identity,
  isFormValid,
  onClose,
  options,
  threshold,
  updateBusinessUnit,
}) => {
  const { country, id, product, region } = identity;

  return (
    <Container>
      <BusinessUnit>
        <FormattedMessage id={`BusinessUnit.${id}`} />
      </BusinessUnit>

      <Breadcrumb>
        <FormattedMessage id={`Product.Short.${product}`} />
        <ArrowIcon />
        <FormattedMessage id={`Region.${region}`} />
      </Breadcrumb>

      <Instructions>
        The specificies you set are only saved when you save the country
        configuration.
      </Instructions>

      <ThresholdContainer>
        <Threshold threshold={threshold} />
      </ThresholdContainer>

      {options && (
        <UrgencyContainer>
          <Urgency options={options} />
        </UrgencyContainer>
      )}

      <ActionButtons>
        <UpdateButton
          onClick={() =>
            updateBusinessUnit(
              { country, id, product, region },
              threshold,
              options
            )
          }
          isDisabled={!isFormValid}
        >
          Update
        </UpdateButton>

        <Button variant={Button.SECONDARY} type="button" onClick={onClose}>
          Cancel
        </Button>
      </ActionButtons>
    </Container>
  );
};

EditBusinessUnitModal.propTypes = {
  identity: PropTypes.object.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  options: PropTypes.array,
  threshold: PropTypes.object,
  updateBusinessUnit: PropTypes.func.isRequired,
};

export default EditBusinessUnitModal;
