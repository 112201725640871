// 9fbef606107a605d69c0edbcd8029e5d

import { combineReducers } from "redux";
import rolloutReducer, * as rollout from "./rollout";

export default combineReducers({
  rollout: rolloutReducer,
});

export const getRolloutCountries = (state) =>
  rollout.getCountries(state.rollout);

export const getReleaseStatus = (state) =>
  rollout.getReleaseStatus(state.rollout);

export const getMapDisplayMode = (state) =>
  rollout.getMapDisplayMode(state.rollout);

export const getMapRegion = (state) => rollout.getMapRegion(state.rollout);
