// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  FETCH_GAQ_RELEASE_NOTES_FAILURE,
  FETCH_GAQ_RELEASE_NOTES_SUCCESS,
  FETCH_GAQ_RELEASE_NOTES,
  RELOAD_GAQ_RELEASE_NOTES_FAILURE,
  RELOAD_GAQ_RELEASE_NOTES_SUCCESS,
  RELOAD_GAQ_RELEASE_NOTES,
} from "../../../../actions";
import RD from "../../../../utils/functional/remote-data";
import * as reducers from "../../..";

const fetchGAQReleaseNotes = () => ({
  type: FETCH_GAQ_RELEASE_NOTES,
});

const fetchGAQReleaseNotesSuccess = (payload) => ({
  type: FETCH_GAQ_RELEASE_NOTES_SUCCESS,
  payload,
});

const fetchGAQReleaseNotesFailure = (payload) => () => ({
  type: FETCH_GAQ_RELEASE_NOTES_FAILURE,
  payload,
  error: true,
});

const reloadGAQReleaseNotes = () => ({
  type: RELOAD_GAQ_RELEASE_NOTES,
});

const reloadGAQReleaseNotesSuccess = (payload) => ({
  type: RELOAD_GAQ_RELEASE_NOTES_SUCCESS,
  payload,
});

const reloadGAQReleaseNotesFailure = (payload) => () => ({
  type: RELOAD_GAQ_RELEASE_NOTES_FAILURE,
  payload,
  error: true,
});

export const reloadGAQReleaseNotesEffect =
  () =>
  (dispatch, getState, { api }) => {
    dispatch(reloadGAQReleaseNotes());
    api
      .fetchGAQReleaseNotes()
      .then(compose(dispatch, reloadGAQReleaseNotesSuccess))
      .catch(compose(dispatch, reloadGAQReleaseNotesFailure));
  };

export const fetchGAQReleaseNotesEffect =
  () =>
  (dispatch, getState, { api }) => {
    const releaseNotes = reducers.getGAQReleaseNotesDecomposed(getState());

    if (RD.isSuccess(releaseNotes) || RD.isLoading(releaseNotes)) {
      return;
    }

    dispatch(fetchGAQReleaseNotes());

    api
      .fetchGAQReleaseNotes()
      .then(compose(dispatch, fetchGAQReleaseNotesSuccess))
      .catch(compose(dispatch, fetchGAQReleaseNotesFailure));
  };
