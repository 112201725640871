// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  GET_FS_ANALYTICS,
  GET_FS_ANALYTICS_FAILURE,
  GET_FS_ANALYTICS_SUCCESS,
} from "../../../../../actions";
import * as reducers from "../../../..";
import RD from "../../../../../utils/functional/remote-data";

const fetchFSAnalytics = () => ({
  type: GET_FS_ANALYTICS,
});

const fetchFSAnalyticsSuccess = (payload) => ({
  type: GET_FS_ANALYTICS_SUCCESS,
  payload,
});

const fetchFSAnalyticsFailure = (payload) => ({
  type: GET_FS_ANALYTICS_FAILURE,
  error: true,
  payload,
});

export const fetchFSAnalyticsEffect =
  () =>
  (dispatch, getState, { api }) => {
    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));

    dispatch(fetchFSAnalytics());

    api
      .fetchFSLeadNumbers(accessToken)
      .then(compose(dispatch, fetchFSAnalyticsSuccess))
      .catch(compose(dispatch, fetchFSAnalyticsFailure));
  };
