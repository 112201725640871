import React, { useState, useRef, memo } from "react";
import PropTypes from "prop-types";
import styled, { keyframes } from "styled-components";
import { IconShareIos, Text, THEME } from "@dhl-official/react-ui-library";
import { MESSAGE_DISPLAY_DURATION } from "../../../../utils/constants";

// #region Component Styles
const slideIn = keyframes`
  from {
    transform: translateY(-10px);
    opacity: 0;
  }
  to {
    transform: 0;
    opacity: 1;
  }
`;

const Label = styled(Text).attrs({})`
  color: ${({ $shouldHighlight }) =>
    $shouldHighlight
      ? THEME.getPrimaryColor("400")
      : THEME.getFontColor("regular")};

  animation: ${THEME.getAnimationSpeed("regular")} ease-in-out 0s forwards
    ${slideIn};
`;

const ShareButton = styled.button.attrs({ "aria-label": "Share Release" })`
  background: 0;
  border: 0;
  cursor: pointer;
  display: flex;
  justify-content: flex-end;
  margin-top: ${THEME.getUnit("regular")};
  outline: 0;
  transition: color ${THEME.getAnimationSpeed("regular")};
  width: 100%;

  &:hover,
  &:focus {
    color: ${THEME.getPrimaryColor("400")};
  }
`;

const ShareIcon = styled(IconShareIos)`
  color: ${({ $shouldHighlight }) =>
    $shouldHighlight
      ? THEME.getPrimaryColor("400")
      : THEME.getFontColor("regular")};
  margin-left: ${THEME.getUnit("small")};
  width: 20px;
`;
// #endregion

const SmallShare = ({ url }) => {
  const interval = useRef();
  const [isCopied, setIsCopied] = useState(false);

  const copyToClipboard = () => {
    const el = document.createElement("textarea");
    el.value = url;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    if (!interval.current) {
      setIsCopied(true);
      interval.current = setTimeout(() => {
        setIsCopied(false);
        clearTimeout(interval.current);
        interval.current = null;
      }, MESSAGE_DISPLAY_DURATION);
    }
  };

  return (
    <ShareButton onClick={copyToClipboard}>
      {isCopied ? (
        <Label $shouldHighlight key={1}>
          Link Copied!
        </Label>
      ) : (
        <Label key={2}>Copy link to this item</Label>
      )}
      <ShareIcon $shouldHighlight={isCopied} />
    </ShareButton>
  );
};

SmallShare.propTypes = {
  url: PropTypes.string.isRequired,
};

export default memo(SmallShare);
