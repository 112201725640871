// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable import/prefer-default-export */

import { FOCUS_ON_RELEASED_VERSION } from "../../../../actions";

export const focusOnReleaseVersion =
  (version) =>
  (dispatch, _, { document }) => {
    dispatch({ type: FOCUS_ON_RELEASED_VERSION, payload: version });

    const element = document.getElementById(`release-version-${version}`);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };
