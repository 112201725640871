// 9fbef606107a605d69c0edbcd8029e5d

import { ROUTES } from "../../../utils/constants";
import {
  DOWNLOAD_COUNTRY_CONFIGURATION,
  SET_CJ_MAP_DISPLAY_MODE,
  SET_CJ_MAP_REGION,
  SET_CJ_ROLLOUT_APPLICATION_FILTER_EFFECT,
  TOGGLE_CJ_ROLLOUT_STATUS_FILTER,
} from "../../../actions";

const downloadCountryConfiguration = (payload) => ({
  type: DOWNLOAD_COUNTRY_CONFIGURATION,
  payload,
});

export const downloadCountryConfigurationEffect =
  (element, country) =>
  (dispatch, _, { window, getHtml2Canvas }) => {
    dispatch(downloadCountryConfiguration(country));

    getHtml2Canvas().then((html2canvas) =>
      html2canvas(element, {
        scrollX: 0,
        scrollY: -window.scrollY,
        onclone: (clonedDocument) => {
          const svgElements = clonedDocument.querySelectorAll("svg");
          const divElements = clonedDocument.querySelectorAll("div");

          // Adjusting SVGs before copying
          svgElements.forEach((item) => {
            item.setAttribute("width", item.getBoundingClientRect().width);
            item.setAttribute("height", item.getBoundingClientRect().height);
          });

          // Adjusting DIVs before copying
          divElements.forEach((item) => {
            // eslint-disable-next-line no-param-reassign
            item.style.boxShadow = "none";
          });
        },
      }).then((canvas) => {
        const dataURL = canvas.toDataURL("image/png");

        const date = new Date();
        const day = date.getDate();
        const month = date.getMonth() + 1;
        const year = date.getFullYear();

        const anchor = document.createElement("a");
        anchor.href = dataURL;
        anchor.download = `FS-Config-${country}-${day}.${month}.${year}.png`;

        anchor.click();
      })
    );
  };

export const setCJMapDisplayMode = (payload) => ({
  type: SET_CJ_MAP_DISPLAY_MODE,
  payload,
});

export const setCJMapRegion = (payload) => ({
  type: SET_CJ_MAP_REGION,
  payload,
});

export const setCJRolloutApplicationFilterEffect =
  (payload) =>
  (dispatch, _, { history }) => {
    dispatch({ type: SET_CJ_ROLLOUT_APPLICATION_FILTER_EFFECT });

    history.push(`${ROUTES.CJ_ROLLOUT_OVERVIEW}/${payload}`);
  };

export const toggleCJRolloutStatusFilter = (payload) => ({
  payload,
  type: TOGGLE_CJ_ROLLOUT_STATUS_FILTER,
});
