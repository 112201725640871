import React, { Fragment } from "react";
import styled from "styled-components";
import { THEME, CheckboxField, Text } from "@dhl-official/react-ui-library";
import PropTypes from "prop-types";
import { THIRD_QUESTION } from "../../../../utils/constants";

// #region Component Styles
const Title = styled(Text).attrs({ isParagraph: true })`
  font-weight: 600;
  margin: 0;
  margin-bottom: ${THEME.getUnit("small")};
`;

const UrgencyCheckbox = styled(CheckboxField)`
  display: block;
  margin-bottom: ${THEME.getUnit("small")};

  :last-of-type {
    margin-bottom: 0;
  }
`;
// #endregion

const Urgency = ({ options, setBusinessUnitUrgency }) => (
  <Fragment>
    <Title>Urgency options</Title>
    <UrgencyCheckbox
      isChecked={options.includes(THIRD_QUESTION.EXPEDITED)}
      onChange={() => setBusinessUnitUrgency(THIRD_QUESTION.EXPEDITED)}
      value={THIRD_QUESTION.EXPEDITED}
    >
      Express
    </UrgencyCheckbox>
    <UrgencyCheckbox
      isChecked={options.includes(THIRD_QUESTION.STANDARD)}
      onChange={() => setBusinessUnitUrgency(THIRD_QUESTION.STANDARD)}
      value={THIRD_QUESTION.STANDARD}
    >
      Standard
    </UrgencyCheckbox>
  </Fragment>
);

Urgency.propTypes = {
  setBusinessUnitUrgency: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.string).isRequired,
};

export default Urgency;
