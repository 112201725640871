// 9fbef606107a605d69c0edbcd8029e5d

/* eslint-disable import/prefer-default-export */

import { TOGGLE_FS_ANALYTICS_BU_FILTER } from "../../../../../actions";

export const toggleFSAnalyticsBUFilter = (payload) => ({
  type: TOGGLE_FS_ANALYTICS_BU_FILTER,
  payload,
});
