// 9fbef606107a605d69c0edbcd8029e5d

import { compose } from "ramda";
import {
  GET_GAQ_RELEASE_NOTE_FAILURE,
  GET_GAQ_RELEASE_NOTE_SUCCESS,
  GET_GAQ_RELEASE_NOTE,
  UPDATE_GAQ_RELEASE_NOTE,
  UPDATE_GAQ_RELEASE_NOTE_SUCCESS,
  UPDATE_GAQ_RELEASE_NOTE_FAILURE,
  SAVE_GAQ_RELEASE_NOTE,
  SAVE_GAQ_RELEASE_NOTE_SUCCESS,
  SAVE_GAQ_RELEASE_NOTE_FAILURE,
} from "../../../../actions";
import RD from "../../../../utils/functional/remote-data";
import * as reducers from "../../..";

const getGAQReleaseNote = (id) => ({
  payload: id,
  type: GET_GAQ_RELEASE_NOTE,
});

const getGAQReleaseNoteSuccess = (payload) => ({
  payload,
  type: GET_GAQ_RELEASE_NOTE_SUCCESS,
});

const getGAQReleaseNoteFailure = (payload) => () => ({
  type: GET_GAQ_RELEASE_NOTE_FAILURE,
  payload,
  error: true,
});

const updateReleaseNote = (id, release) => ({
  payload: { id, release },
  type: UPDATE_GAQ_RELEASE_NOTE,
});

const updateReleaseNoteSuccess = (id, release) => () => ({
  payload: { id, release },
  type: UPDATE_GAQ_RELEASE_NOTE_SUCCESS,
});

const updateReleaseNoteFailure = (error) => ({
  error: true,
  payload: error,
  type: UPDATE_GAQ_RELEASE_NOTE_FAILURE,
});

const saveReleaseNote = () => ({
  payload: {},
  type: SAVE_GAQ_RELEASE_NOTE,
});

const saveReleaseNoteSuccess = (release) => () => ({
  payload: { release },
  type: SAVE_GAQ_RELEASE_NOTE_SUCCESS,
});

const saveReleaseNoteFailure = (error) => ({
  error: true,
  payload: error,
  type: SAVE_GAQ_RELEASE_NOTE_FAILURE,
});

export const getGAQReleaseNoteEffect =
  (id) =>
  (dispatch, getState, { api }) => {
    const currentRelease = reducers.getReleaseNote(getState());

    if (id) {
      if (RD.isSuccess(currentRelease) || RD.isLoading(currentRelease)) {
        return;
      }
    } else {
      // If we try a get without ID, it returns all release notes;
      RD.isSuccess({});
    }

    dispatch(getGAQReleaseNote(id));

    api
      .getGAQReleaseNote(id)
      .then(compose(dispatch, getGAQReleaseNoteSuccess))
      .catch(compose(dispatch, getGAQReleaseNoteFailure));
  };

export const updateGAQReleaseNoteEffect =
  (id, currentRelease) =>
  (dispatch, getState, { api }) => {
    dispatch(updateReleaseNote(id, currentRelease));

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));
    const requestBody = currentRelease;

    api
      .updateGAQReleaseNote(accessToken, id, requestBody)
      .then(compose(dispatch, updateReleaseNoteSuccess(id, currentRelease)))
      .catch(compose(dispatch, updateReleaseNoteFailure));
  };

export const saveGAQReleaseNoteEffect =
  (currentRelease) =>
  (dispatch, getState, { api }) => {
    dispatch(saveReleaseNote(currentRelease));

    const accessToken = RD.withDefault("", reducers.getLoggedUser(getState()));
    const requestBody = currentRelease;

    api
      .saveGAQReleaseNote(accessToken, requestBody)
      .then(compose(dispatch, saveReleaseNoteSuccess(currentRelease)))
      .catch(compose(dispatch, saveReleaseNoteFailure));
  };
