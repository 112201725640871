// 9fbef606107a605d69c0edbcd8029e5d

import { identity } from "ramda";
import {
  GET_GAQ_RELEASE_NOTE_FAILURE,
  GET_GAQ_RELEASE_NOTE_SUCCESS,
  GET_GAQ_RELEASE_NOTE,
} from "../../../../actions";
import RD from "../../../../utils/functional/remote-data";

const INITIAL_STATE = RD.notAsked();

const reducer = (state = INITIAL_STATE, action) => {
  const { type, payload } = action;

  switch (type) {
    case GET_GAQ_RELEASE_NOTE:
      return RD.loading();

    case GET_GAQ_RELEASE_NOTE_SUCCESS:
      return RD.success(payload);

    case GET_GAQ_RELEASE_NOTE_FAILURE:
      return RD.error(payload);

    default:
      return state;
  }
};

export default reducer;

export const get = identity;
