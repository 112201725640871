import React from "react";
import styled from "styled-components";
import {
  THEME,
  IconHeartFilled,
  Text,
  Link,
} from "@dhl-official/react-ui-library";
import { MAX_WIDTH } from "../../styles";

// #region Component Styles
const Container = styled.footer`
  margin-top: calc(${THEME.getUnit("large")} * 2);
  padding: ${THEME.getUnit("regular")} ${THEME.getUnit("large")};
`;

const Content = styled(Text).attrs({
  isParagraph: true,
  size: Text.SMALL,
})`
  color: ${THEME.getGrayColor("600")};
  margin: auto;
  max-width: ${MAX_WIDTH};
  padding: 0;
  text-align: center;
`;

const Love = styled(IconHeartFilled).attrs({
  "aria-label": "Love",
  role: "img",
  title: "Love",
})`
  color: ${THEME.getPrimaryColor("400")};
  height: 1em;
  margin-bottom: -2px;
`;

const ExternalLink = styled(Link).attrs({
  rel: "noreferrer",
  target: "_blank",
})`
  color: ${THEME.getGrayColor("800")};
  text-decoration: none;
`;

// #endregion

const Footer = () => (
  <Container>
    <Content>
      Made with <Love /> and the{" "}
      <ExternalLink href="https://publicis.invisionapp.com/dsm/publicis/dhl-ui-library">
        DHL User Interface Library
      </ExternalLink>
      .
      <br />
      Want to see more content here?
      <br />
      <ExternalLink href="mailto:dcihub@dhl.com?subject=DCI Hub - Feature Idea">
        Tell us
      </ExternalLink>{" "}
      your ideas. Your feedback is greatly appreciated.
    </Content>
  </Container>
);

export default Footer;
