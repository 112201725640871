// 9fbef606107a605d69c0edbcd8029e5d

import { memo } from "react";
import { connect } from "react-redux";
import * as reducers from "../../reducers";
import * as actions from "../../reducers/actions";
import SignIn from ".";

const mapStateToProps = (state) => ({
  loggedUser: reducers.getLoggedUser(state),
});

const mapDispatchToProps = {
  signIn: actions.signInEffect,
};

export default memo(connect(mapStateToProps, mapDispatchToProps)(SignIn));
